import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import star from "../../../assets/images/list-view-1.png";
import shadeBg from "../../../assets/images/bg-shade.png";
import eyeIcon from "../../../assets/images/eye.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { capitalizeFirstLowercaseRest } from "../../../constants/constant";

const ContentCard = (props) => {
  const navigate = useNavigate();
  const [starActive, setStarActive] = React.useState(true);

  const contentDetail = (id) => {
    navigate("/content_details/" + id + "/" + props?.activeTab);
  }

  function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  }

  return (
    <div>
      <Card
        sx={{ maxWidth: "100%" }}
        style={{ backgroundColor: "transparent", boxShadow: "0px 0px 0px 0px" }}
        onClick={() => contentDetail(props?.singleObject?.ID)}
      >
        <Box className="card-image" sx={{ flexDirection: "column", cursor: 'pointer' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="250"
            image={props?.singleObject?.Thumbnailfile ? props?.singleObject?.Thumbnailfile : star}
            style={{ borderRadius: "10px" }}
          />
          <div className="ab-star-wrap">
            {starActive ? (
              <StarBorderIcon
                onClick={() => {
                  setStarActive(!starActive);
                }}
              />
            ) : (
              <StarRateIcon
                style={{ color: "yellow" }}
                onClick={() => {
                  setStarActive(!starActive);
                }}
              />
            )}
            <p>{props?.singleObject?.overallrating ? props?.singleObject?.overallrating : 0.0}</p>
          </div>
          <div className="ab-shade">
            <img src={shadeBg} alt="shade" />
          </div>
          <div className="tag-name">
            <p>{props?.singleObject?.jp_determinant?.Determinant ? props?.singleObject?.jp_determinant?.Determinant : "NATURE"}</p>
          </div>
        </Box>
        <Box sx={{ flexDirection: "column", padding: "10px 0px" }}>
          <div className="pos-ab"></div>
          <CardContent sx={{ padding: "0px" }}>
            <Typography gutterBottom variant="h5" component="div" className="content-head-sub-title">
              {props?.singleObject?.ContentTitle ? props?.singleObject?.ContentTitle : 'Random character string 100 words'}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
              <div className="by-author">by {capitalizeFirstLowercaseRest(props?.singleObject?.jp_contentorguser?.jp_contentuserprofiles[0]?.DisplayName)}</div>
              <div className="eye-count">
                <img src={eyeIcon} alt="eyeIcon" /> {kFormatter(props?.singleObject?.numviews ? props?.singleObject?.numviews : 0)}
              </div>
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </div>


  );
}

ContentCard.propTypes = {};
ContentCard.defaultProps = {};

const actionCreators = {

};

export default connect(
  ({ content }) => ({
    allContentListLoading: content.allContentListLoading
  }),
  actionCreators
)(ContentCard);
