import Chart from "react-apexcharts";
const DonutChart = (props) => {
  const week = [
    props.counts[2]?.Content_count,
    props.counts[3]?.Content_count,
    props.counts[0]?.Content_count,
  ];
  const month = [
    props.counts[2]?.Content_count + 10,
    props.counts[3]?.Content_count + 20,
    props.counts[0]?.Content_count + 80,
  ];
  const year = [
    props.counts[2]?.Content_count + 50,
    props.counts[3]?.Content_count + 75,
    props.counts[0]?.Content_count + 100,
  ];

  const getDataResult = () => {
    if (props.change === "week") {
      return week;
    } else if (props.change === "month") {
      return month;
    } else if (props.change === "year") {
      return year;
    } else {
      return week;
    }
  };
  const change = getDataResult();
  const options = {
    series: change,
    chart: {
      type: "donut",
    },

    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,

        donut: {
          size: 75,

          labels: {
            show: true,
            value: {
              offsetY: -50,
              fontSize: "30px",
              fontFamily: "avenir-bold",
              color: "#fff",
            },
            total: {
              offsetY: -30,
              label: "TOTAL CONTENT",
              show: true,
              showAlways: true,
              fontSize: "10px",
              color: "#727A7B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
    },
    grid: {
      padding: {
        bottom: -120,
      },
    },
    title: {
      text: "Overview",
      align: "left",
      style: {
        fontSize: "18px",
        fontFamily: "avenir-semi",
        fontWeight: "regular",
        color: "#fff",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 210,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    labels: ["Approved", "Under Review", "Disapproved"],
    colors: ["#1FDFF4", "#E9990E", "#E60C22"],
  };
  const series = change;
  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height={320}
      />
    </div>
  );
};

export default DonutChart;
