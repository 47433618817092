import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import UserDetails from '../UserDetails';
import { connect, useDispatch } from 'react-redux';
import { userProfileModalToggle } from '../../redux/action/user';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 0,
    backgroundColor: '#263133',
    
};

const UserProfileModal = (props) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        keepMounted
        open={props?.toggleProfileModal?.toggle}
        onClose={() => dispatch(userProfileModalToggle(false, ''))}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} style={{width:'130vh'}}>
          <UserDetails activePage={'UserProfileModal'} data={props?.cuntentDetailUser?.data}/>
        </Box>
      </Modal>
    </div>
  );
}

UserProfileModal.propTypes = {};
UserProfileModal.defaultProps = {};

export default connect(
    ({ user, content }) => ({
      toggleProfileModal: user.userProfileModalToggle,
      userProfileModal: user.userProfileModalToggle,
      cuntentDetailUser: content.cuntentDetailUser
    }),
)(UserProfileModal);