import React from 'react';
import Chart from 'react-apexcharts';
import './EngejmentChart.scss';

const EngejmentChart = () => {
  const initialData = {
    options: {
      labels: [
        '70% Droped the video after 00:10 min',
        '10% Watched the full video',
        '20% Watched the half video'
      ],
      colors: [
        '#FAAA2F',
        '#D33336',
        '#0BD7EE'
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        enabled: true,
        position: 'bottom',
        horizontalAlign: 'start', 
       
        labels: {
          colors: '#fff',

        },
        itemMargin: {
          horizontal:30,
          vertical: 5,
          
          
        },
      },
      stroke: {
        show: true,
        width: 0,
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            minAngleToShowLabel: 10,
          },
          donut: {
            size: '72%',
           
            labels: {
              show: true,
              
              value: {
                show: true,
                color: '#fff',
                
              },
              total: {
                show: true,
                label: 'TOTAL STUDENTS',
                fontSize: '12px',
                fontWeight: 600,
                color: '#727a7b',
                showAlways: true,
                
              },
            },
          },
        },
      },
    },
    series: [180, 40, 80,],
  }

  return (
    <div className='justify-content-center'>
      <h2 className='text-uppercase text-center white lead chart-title'>engagement percent</h2>
      <Chart options={initialData.options} series={initialData.series} type="donut" width="430" />
    </div>
  );
}

export default EngejmentChart;