import React, {useEffect} from "react";
import "./login.scss";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/action/user";
import { useNavigate } from 'react-router-dom';
import leftImage from "../../assets/images/left-img.png";
import LoginSection from "../../component/loginSection";
import OtpSection from "../../component/otpSection";
import LoginForgotPassword from "../../component/ForgotPassword/index";
import leftInnerImage from "../../assets/images/left-inner-img.png";
import ConfirmPassword from "../../component/confirmPassword";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

const Login = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const verify = localStorage.getItem('isLogin');
    if(verify === "true"){
      navigate("/dashboard");
    }
  }, [localStorage.getItem('isLogin')])
   
  return (
    <div className="login-container">
      <div className="flex-center">
        <div className="out-box-wrap">
          <Card
            sx={{
              display: "flex",
              borderRadius: "15px",
              backgroundColor: "transparent",
              height:"580px",
              width:"945px",
            }}
          >
            <Box className="left-side-box" sx={{ width: "50%" }}>
              <div className="text-center company__info">
                <img
                  src={leftImage}
                  width="100%"
                  height="100%"
                  alt="Company Logo"
                />
                <div className="ab-inner-image-wrap">
                  <img src={leftInnerImage} alt="Company Logo" />
                </div>
              </div>
            </Box>
            <Box className="right-side-box" sx={{ width: "55%" }}>
              {props.user.status === "login" ? (
                <LoginSection />
              ) : props.user.status === "otp" ? (
                <OtpSection />
              ) : props.user.status === "LoginForgotPassword" ? (
                <LoginForgotPassword />
              ) : props.user.status === "confirmPassword" ? (
                <ConfirmPassword />
              ) : (
                <>
                  <p className="subTitle">Opps</p>
                </>
              )}
            </Box>
          </Card>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};
Login.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Login);