import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserData } from "../redux/action/user";
import Login from "../pages/login";
import UserManagementList from "../pages/userManagement/userManagementList";
import Dashboard from "../pages/Dashboard";
import Contents from "../pages/contents";
import Setting from "../pages/Setting/Setting";
import ProtectedRout from "./ProtectedRout";
import Determinants from "../pages/determinants/Determinants";
import Help from "../pages/help/Help";
import ContentDetails from "../pages/contentDetails/ContentDetail";
import DeterminantsDetail from "../pages/DeterminantsDetail/DeterminantsDetail";
import CreateContent from "../pages/createContent/createContent";


const RouteContainer = (props) => {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="" element={<ProtectedRout />}>
          <Route path="/user_management_list" element={<UserManagementList />} />  {/* user_management_list  */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/content/:Under_Review" exact element={<Contents />} />
          <Route path="/Setting" exact element={<Setting />} />
          <Route path="/determinants" exact element={<Determinants />} />
          <Route path="/help" exact element={<Help />} />
          <Route path="/content_details/:id/:activeTabName" element={<ContentDetails />} /> {/* content_details/:id/active_tab_name  */}
          <Route path="/determinants_details/:id" exact element={<DeterminantsDetail />} /> {/* determinants_details  */}
          <Route path="/content/create_new_content" exact element={<CreateContent />} />
        </Route>
      </Routes>
    </div>
  );
};

RouteContainer.propTypes = {};
RouteContainer.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(RouteContainer);
