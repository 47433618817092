import React from 'react';
import './confirmPassword.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user'
import TextBox from '../../atoms/textbox';
import ButtonInput from '../../atoms/button';
import sms from '../../assets/images/sms.png';
import passwordCheck from '../../assets/images/password-check.png';
import backButton from '../../assets/images/arrow-left.svg';

const ConfirmPassword = (props) => {

    const emailChange = (value) => {
        props.updateUserData({
            ...props.user,
            userName: value
        })
    }

    const passwordChange = (value) => {
        props.updateUserData({
            ...props.user,
            password: value
        })
    }

    const gotoOTP = () => {
        props.updateUserData({
            ...props.user,
            status: 'otp'
        })
    }

    const gotoLogin = () => {
        props.updateUserData({
            ...props.user,
            status: 'login',
            isNewUser: true,
        })
    }

    return (
        <div className="login_form">
            <div className="container-fluid">
                <div className="row">
                    <h2 className='title'>You're almost there!</h2>
                    <p className='subTitle'>
                        Start resetting your password
                    </p>
                </div>
                <TextBox placeholder="Confirm Password" value={props.user.userName} valueChange={emailChange} prefix={sms} />
                <TextBox placeholder="Password" type="password" value={props.user.password} valueChange={passwordChange} prefix={passwordCheck} />
                <div>
                    <ButtonInput text={'SUBMIT'} onSubmit={gotoLogin} />
                    <ButtonInput text="Back" class="back-button" onSubmit={gotoOTP} iCon={backButton} />
                </div>
            </div>
        </div>
    );
};

ConfirmPassword.propTypes = {};
ConfirmPassword.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(ConfirmPassword);