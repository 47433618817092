import React, { Component } from "react";
import Slider from "react-slick";
import './Slider.scss';
import SliderCard from "../SliderCard";


export default class MultipleItems extends Component {

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1
    };
    return (
      <div>

        <Slider {...settings}>

          <SliderCard
            rating={4.3}
            personName={"David Jones"}
            profession={"Therapist"}
            points={4.5}
          />

          <SliderCard
            rating={4.3}
            personName={'David Jones'}
            profession={'Therapist'}
            points={4.5}
          />

          <SliderCard
            rating={4.3}
            personName={'Md Nizam'}
            profession={'Developer'}
            points={4.5}
          />

          <SliderCard
            rating={4.3}
            personName={'David Jones'}
            profession={'Therapist'}
            points={4.5}
          />
          <SliderCard
            rating={4.3}
            personName={'David Jones'}
            profession={'Therapist'}
            points={4.5}
          />

          <SliderCard
            rating={4.3}
            personName={'Md Nizam'}
            profession={'Developer'}
            points={4.5}
          />

          <SliderCard
            rating={4.3}
            personName={'David Jones'}
            profession={'Therapist'}
            points={4.5}
          />

        </Slider>
      </div>
    );
  }

}
