import {
    ALL_CONTENT_LIST_REQUEST,
    ALL_CONTENT_LIST_SUCCESS,
    ALL_CONTENT_LIST_ERR,
    GET_CONTENT_DETAIL_REQUEST,
    GET_CONTENT_DETAIL_SUCCESS,
    GET_CONTENT_DETAIL_ERR,
    GET_CONTENTTYPE_COUNT_REQUEST,
    GET_CONTENTTYPE_COUNT_SUCCESS,
    GET_CONTENTTYPE_COUNT_ERR,
    DIS_APPRUVE_DISAPPRUVE_REQUEST,
    DIS_APPRUVE_DISAPPRUVE_SUCCESS,
    DIS_APPRUVE_DISAPPRUVE_ERR,
    APPROVE_CONTENT_REQUSET,
    APPROVE_CONTENT_SUCCESS,
    APPROVE_CONTENT_ERR,
    REWORK_CONTENT_REQUSET,
    REWORK_CONTENT_SUCCESS,
    REWORK_CONTENT_ERR,
    DETERMINANT_REQUEST,
    DETERMINANT_SUCCESS,
    DETERMINANT_ERR,
    DETERMINANT_CATOGORIES_REQUEST,
    DETERMINANT_CATOGORIES_SUCCESS,
    DETERMINANT_CATOGORIES_ERR,
    DETERMINANT_CATOGORIES_PRACTICES_REQUEST,
    DETERMINANT_CATOGORIES_PRACTICES_SUCCESS,
    DETERMINANT_CATOGORIES_PRACTICES_ERR,
    GET_CONTENT_USER_DETAIL_REQUEST,
    GET_CONTENT_USER_DETAIL_SUCCESS,
    GET_CONTENT_USER_DETAIL_ERR,
    TEACHER_LIST_REQUEST,
    TEACHER_LIST_SUCCESS,
    TEACHER_LIST_ERR,
    CREATE_CONTENT_ADMIN_REQUEST,
    CREATE_CONTENT_ADMIN_SUCCESS,
    CREATE_CONTENT_ADMIN_ERR,
    UPLOAD_FILES_AWS_REQUEST,
    UPLOAD_FILES_AWS_SUCCESS,
    UPLOAD_FILES_AWS_ERR,
    GET_PARTICULAR_DETERMINANT_REQUEST,
    GET_PARTICULAR_DETERMINANT_SUCCESS,
    GET_PARTICULAR_DETERMINANT_ERR,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR
} from "../../constants/ActionTypes";
import axios from "axios";
import { api } from "../../constants/api";
import { toast } from 'react-toastify';

export const getAllContents = () => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: ALL_CONTENT_LIST_REQUEST,
            payload: []
        });
        return axios.get(`${api}/contents/get_all_content`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: ALL_CONTENT_LIST_SUCCESS,
                payload: res.data
            })
        }).catch((err) => {
            dispatch({
                type: ALL_CONTENT_LIST_ERR,
                payload: err
            })
        })
    }
}

export const disapproveContent = (id) => {
    const token = localStorage.getItem("authToken");

    return (dispatch) => {
        dispatch({
            type: DIS_APPRUVE_DISAPPRUVE_REQUEST,
            payload: []
        });
        return axios.post(`${api}/contents/disapprove_content/${id}`, {  },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res) => {
                toast('Disapproved sucessfully!');
                dispatch({
                    type: DIS_APPRUVE_DISAPPRUVE_SUCCESS,
                    payload: res.data
                })
            }).catch((err) => {
                toast("Something went wrong");
                dispatch({
                    type: DIS_APPRUVE_DISAPPRUVE_ERR,
                    payload: err
                })
            })
    }
}

export const approveContent = (id) => {
    return async (dispatch) => {
        const token = localStorage.getItem("authToken");
        dispatch({
            type: APPROVE_CONTENT_REQUSET,
            payload: [],
        });
        return axios.post(`${api}/contents/approve_content/${id}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                toast('Approved successfullt...');
                dispatch({
                    type: APPROVE_CONTENT_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                toast("Something went wrong");

                dispatch({
                    type: APPROVE_CONTENT_ERR,
                    payload: err,
                });
            });
    };
}

export const reworkContent = (id) => {
    return async (dispatch) => {
        const token = localStorage.getItem("authToken");
        dispatch({
            type: REWORK_CONTENT_REQUSET,
            payload: [],
        });
        return axios.post(`${api}/contents/rework_content/${id}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                toast('Content sent for rework');
                dispatch({
                    type: REWORK_CONTENT_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                toast("Something went wrong");

                dispatch({
                    type: REWORK_CONTENT_ERR,
                    payload: err,
                });
            });
    };
}

export const getContentDetail = (id) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: GET_CONTENT_DETAIL_REQUEST,
            payload: []
        });
        return axios.get(`${api}/contents/viewContent/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: GET_CONTENT_DETAIL_SUCCESS,
                payload: res.data
            })
        }).catch((err) => {
            dispatch({
                type: GET_CONTENT_DETAIL_ERR,
                payload: err
            })
        })
    }
}

export const getContentUser = (id) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: GET_CONTENT_USER_DETAIL_REQUEST,
            payload: []
        });
        return axios.get(`${api}/get/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: GET_CONTENT_USER_DETAIL_SUCCESS,
                payload: res.data
            })
        }).catch((err) => {
            dispatch({
                type: GET_CONTENT_USER_DETAIL_ERR,
                payload: err
            })
        })
    }
}

export const determinant = () => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: DETERMINANT_REQUEST,
            payload: []
        });
        return axios.get(`${api}/contents/get_all_determinants`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: DETERMINANT_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: DETERMINANT_ERR,
                payload: err
            })
        })
    }
}

export const determinantCategory = (detereminantType) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: DETERMINANT_CATOGORIES_REQUEST,
            payload: []
        });
        return axios.post(`${api}/contents/get_all_categories`,  {"determinant":detereminantType},{
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: DETERMINANT_CATOGORIES_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: DETERMINANT_CATOGORIES_ERR,
                payload: err
            })
        })
    }
}

export const determinantCategoryPractices = (detereminantPracticeType) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: DETERMINANT_CATOGORIES_PRACTICES_REQUEST,
            payload: []
        });
        return axios.post(`${api}/contents/get_all_practices`,  {"category":detereminantPracticeType},{
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: DETERMINANT_CATOGORIES_PRACTICES_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: DETERMINANT_CATOGORIES_PRACTICES_ERR,
                payload: err
            })
        })
    }
}

export const getTeacherList = () => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: TEACHER_LIST_REQUEST,
            payload: []
        });
        return axios.get(`${api}/get_teacher_list`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: TEACHER_LIST_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: TEACHER_LIST_ERR,
                payload: err
            })
        })
    }
}

export const createContentByAdmin = (id, determinant, category, practice, contentType, contentTitle, originalUrl, referenceLink1, referenceLink2, description, views, ratings, students, thumbnail, navigateFunction) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTENT_ADMIN_REQUEST,
            payload: []
        });
        return axios.post(`${api}/contents/admin_add_content/${id}`, {
            determinant: determinant,
            category: category,
            practice: practice,
            contentType: contentType,
            contentTitle: contentTitle,
            originalUrl: originalUrl,
            referenceLink1: referenceLink1,
            referenceLink2: referenceLink2,
            description: description,
            views: views,
            ratings: ratings,
            students: students,
            thumbnail: thumbnail
        },{
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            toast(res?.data?.message);
            navigateFunction();
            dispatch({
                type: CREATE_CONTENT_ADMIN_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: CREATE_CONTENT_ADMIN_ERR,
                payload: err
            })
        })
    }
}   

export const uploadAllKindOfFiles = (file, step) => {
    const token = localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append('file', file);

    return (dispatch) => {
        dispatch({
            type: UPLOAD_FILES_AWS_REQUEST,
            payload: []
        });
        return axios({
            method: "post",
            url: `${api}/contents/uploadContent`,
            data: formData,
            headers: {Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
          }).then((res) => {
            if(step === 3){
                localStorage.setItem('contenttype', res?.data?.data?.data?.Location);
            }else if(step === 4){
                localStorage.setItem('thumnil',  res?.data?.data?.data?.Location);
            }
            dispatch({
                type: UPLOAD_FILES_AWS_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: UPLOAD_FILES_AWS_ERR,
                payload: err
            })
        })
    }
}

export const getParticularDeterminant = (detereminantName) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: GET_PARTICULAR_DETERMINANT_REQUEST,
            payload: []
        });
        return axios.get(`${api}/contents/get_a_determinant/${detereminantName}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: GET_PARTICULAR_DETERMINANT_SUCCESS,
                payload: res?.data?.data
            })
        }).catch((err) => {
            dispatch({
                type: GET_PARTICULAR_DETERMINANT_ERR,
                payload: err
            })
        })
    }
}

export const getParticularDeterminantDetailsCount = (detereminantName) => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST,
            payload: []
        });
        return axios.post(`${api}/contents/get_count_by_determinant`,{"determinant":detereminantName}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS,
                payload: res?.data
            })
        }).catch((err) => {
            dispatch({
                type: GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR,
                payload: err
            })
        })
    }
}

export const getUnderReviewCount = () => {
    const token = localStorage.getItem("authToken");
    return (dispatch) => {
        dispatch({
            type: GET_CONTENTTYPE_COUNT_REQUEST,
            payload: []
        });
        return axios.get(`${api}/contents/get_count_by_status`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            dispatch({
                type: GET_CONTENTTYPE_COUNT_SUCCESS,
                payload: res.data
            })
        }).catch((err) => {
            dispatch({
                type: GET_CONTENTTYPE_COUNT_ERR,
                payload: err
            })
        })
    }
}