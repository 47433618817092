import React from 'react';
import './topcontentcard.scss';
import PlayButton from '../../assets/images/play-button.png';
import Yoga from '../../assets/images/yoga.jpg';
import Women from '../../assets/images/woman.png';
import View from '../../assets/images/eye-white.png';

const TopContentCard = () => {
  return (
    <>
      <div className="card topcontentcard-container p-3">
        <img src={Yoga} className="card-img-top" alt="..." />
        <div className="play-button">
          <img src={PlayButton} alt="play" className="playBtn" />
        </div>
        <div className="card-body mx-2 d-flex justify-content-center flex-column">
          <h5>Mar 22,2022</h5>
          <h6 className="">Down Ward Fancing Dog (adho mukha Svanasana)</h6>
          <div className="d-flex justify-content-between">
            <p className="card-text-1">
              <span>
                {' '}
                <img src={Women} alt="woman" />
              </span>
              <span>Dr.Rachel</span>
            </p>
            <h4 className="card-text-2">
              {' '}
              <span>
                {' '}
                <img src={View} alt="view" />
              </span>
              <span>1240</span>
            </h4>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  )
}

export default TopContentCard
