import { Button } from "@mui/material";
import React, { useState } from "react";
import "./button.scss";
import EastRoundedIcon from '@mui/icons-material/EastRounded';

const ButtonInput = (props) => {
  return (
    <Button
      variant="contained"
      className={`${props.class ? props.class : ""} btn ${props?.addClass}`}
      onClick={() => props.onSubmit && props.onSubmit()}
      disabled={props.disabled ? props.disabled : false}
      style={{ display: "flex", margin: '0px !important' }}
      startIcon={
        props.iCon ? (
          <img
            alt="Back"
            className="prefix margin-left-10 vertical-align"
            src={props.iCon}
          />
        ) : null
      }
    >
      {props.text}
      {
        props.addClass === "extendWidth" && <EastRoundedIcon sx={{ marginLeft: '10px' }} />
      }
    </Button>
  );
};

ButtonInput.propTypes = {};
ButtonInput.defaultProps = {};

export default ButtonInput;
