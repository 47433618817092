import { combineReducers } from 'redux';

import user from './reducer/user';
import content from './reducer/content';

const rootReducer = combineReducers( {
	user,
	content,
	
} );

export default rootReducer;
