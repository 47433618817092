import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Like from "../../../assets/images/likes-2.png";
import Comment from "../../../assets/images/under-review.png";
import DisLike from "../../../assets/images/dislikes.png";
import "./Categories.scss";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '1. Lowerbody - body weight -air squats and lungesLowerbody - body weight -air squats and lunges',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2. Lowerbody - body weight -air ',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;


  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow

        className="table-row categiries-row"
        sx={{
          '& > *': { borderBottom: 'unset' },
          "&:last-child td, &:last-child th": { border: 0 },
          "& th": {
            color: "#fff",
            borderBottom: "1px solid #273334",
          },
          "& td": {
            borderBottom: "1px solid #273334",
            
          },
          
          backgroundColor: "#0f1c1e",
        }}
      >

        <TableCell component="th" scope="row">
          {row.Category}
        </TableCell>
        <TableCell className="text-white" align="right">{row.calories}</TableCell>

        <TableCell className="text-white" align="right">
          <div>
            <span className="totalContentNumber">80 Contents</span>
            <span className="user-table-like statushText">
              {" "}
              <img
                src={Like}
                alt="like"
                width={18}
                style={{ marginRight: "7px" }}
              />
              49
            </span>
            <span className="user-table-like statushText">
              {" "}
              <img
                src={Comment}
                alt="like"
                width={18}
                style={{ marginRight: "7px" }}
              />
              14
            </span>
            <span className="user-table-like statushText">
              {" "}
              <img
                src={DisLike}
                alt="like"
                width={18}
                style={{ marginRight: "7px" }}
              />
              17
            </span>
          </div>
        </TableCell>

        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            sx={{ color: "#FAAA2F" }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        className="table-row sub-categiries-row"
        sx={{
          '& > *': { borderBottom: 'unset' },
          "&:last-child td, &:last-child th": { border: 0 },
          "& th": {
            color: "#fff",
            borderBottom: "1px solid #273334",
          },
          "& td": {
            borderBottom: "1px solid #273334",
          },
          backgroundColor: "#0a1719",
        }}>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>

              <Table size="small" aria-label="purchases">

                <TableBody>
                  {/* {row.history.map((historyRow, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '& > *': { borderBottom: 'unset' },
                        padding: "0px !important",
                        "& th": {
                          color: "#fff",
                          borderBottom: "1px solid #273334 !important",
                        },
                        "& td": {
                          borderBottom: "1px solid #273334 !important",
                          padding: "16px 15px"
                        },
                        "&:last-child td, &:last-child th": { borderBottom: "0px !important" },
                      }}
                      >

                      <TableCell className="text-white">1. Lowerbody - body weight -air squats and lungesLowerbody - body weight -air squats and lunges</TableCell>
                      <TableCell colSpan={3} className="text-white" align="left">
                        <div>
                          <span className="totalContentNumber">80 Contents</span>
                          <span className="user-table-like statushText">
                            {" "}
                            <img
                              src={Like}
                              alt="like"
                              width={18}
                              style={{ marginRight: "7px" }}
                            />
                            49
                          </span>
                          <span className="user-table-like statushText">
                            {" "}
                            <img
                              src={Comment}
                              alt="like"
                              width={18}
                              style={{ marginRight: "7px" }}
                            />
                            14
                          </span>
                          <span className="user-table-like statushText">
                            {" "}
                            <img
                              src={DisLike}
                              alt="like"
                              width={18}
                              style={{ marginRight: "7px" }}
                            />
                            17
                          </span>
                        </div>
                      </TableCell>

                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,

  }).isRequired,
};

const rows = [
  createData('Build Strength', '7 Sub-Categories', 6.0, 24, 3.99),
  createData('Dance to my favorite song or playlist', '7 Sub-Categories', 6.0, 24, 3.99),

];

export default function CategoriesTable(props) {
  return (
    <TableContainer component={Paper}
      sx={{
        backgroundColor: "transparent",
        borderRadius: "0px",
        color: "#fff",
        "& th": {
          color: "#fff",
        },
      }}>
      <Table  aria-label="collapsible table">
        <TableBody>
          {props?.determinantCategories?.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
