import React from "react";
import "./LinkTag.scss";
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Clipcopy from "../../assets/images/clipcopy.png";
import Link1 from "../../assets/images/link.png";

const LinkTag = (props) => {

    const copyText = (text) => {
        var textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast('Text Copied');
    }
    return (
        <FormControl sx={{ m: 1, borderStyle: "dashed !important", }} variant="outlined">
            <OutlinedInput
                sx={{
                    color: "white !important",
                    fieldset: {
                        border: 'none'
                    }
                }}
                id="outlined-adornment-weight"
                value={props?.data}
                startAdornment={<InputAdornment position="start" sx={{ borderRight: "1px solid #2e393b", color: "#82898a", height: "auto", width: "auto", paddingRight: "10px" }}> <img src={Link1} /> &nbsp; Link 1 &nbsp;</InputAdornment>}
                endAdornment={<InputAdornment position="end" style={{ cursor: 'pointer' }}><img src={Clipcopy} onClick={() => copyText(props?.data)} /></InputAdornment>}
            />
        </FormControl>
    )
}

export default LinkTag;