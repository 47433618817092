export const SET_USER_DATA = 'SET_USER_DATA';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERR = 'LOGIN_ERR';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERR = 'SEND_EMAIL_ERR';

export const SHOW_DETAIL = "SHOW_DETAIL";

export const GET_ALL_USER_REQUEST = "GET_ALL_USER_REQUEST";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_ERR = "GET_ALL_USER_ERR";

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_ERR = "GET_USER_DETAIL_ERR";

export const GRIDE_TOGGLE = "GRIDE_TOGGLE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCESS = "FORGOT_PASSWORD_SUCESS";
export const FORGOT_PASSWORD_ERR = "FORGOT_PASSWORD_ERR";

export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERR = "VERIFY_USER_ERR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERR = "DELETE_USER_ERR";


export const MODEL_TOGGLE = "MODEL_TOGGLE";

export const CONTANT_ACTIVE_TAB = "CONTANT_ACTIVE_TAB";

export const DRAWER_TOGGLE = "DRAWER_TOGGLE";

export const ALL_CONTENT_LIST_REQUEST = "ALL_CONTENT_LIST_REQUEST";
export const ALL_CONTENT_LIST_SUCCESS = "ALL_CONTENT_LIST_SUCCESS";
export const ALL_CONTENT_LIST_ERR = "ALL_CONTENT_LIST_ERR";

export const GET_CONTENT_DETAIL_REQUEST = "GET_CONTENT_DETAIL_REQUEST";
export const GET_CONTENT_DETAIL_SUCCESS = "GET_CONTENT_DETAIL_SUCCESS";
export const GET_CONTENT_DETAIL_ERR = "GET_CONTENT_DETAIL_ERR";

export const GET_CONTENTTYPE_COUNT_REQUEST = "GET_CONTENTTYPE_COUNT_REQUEST";
export const GET_CONTENTTYPE_COUNT_SUCCESS = "GET_CONTENTTYPE_COUNT_SUCCESS";
export const GET_CONTENTTYPE_COUNT_ERR = "GET_CONTENTTYPE_COUNT_ERR";

export const DIS_APPRUVE_DISAPPRUVE_REQUEST = "DIS_APPRUVE_DISAPPRUVE_REQUEST";
export const DIS_APPRUVE_DISAPPRUVE_SUCCESS = "DIS_APPRUVE_DISAPPRUVE_SUCCESS";
export const DIS_APPRUVE_DISAPPRUVE_ERR = "DIS_APPRUVE_DISAPPRUVE_ERR";

export const APPROVE_CONTENT_REQUSET = "APPROVE_CONTENT_REQUSET";
export const APPROVE_CONTENT_SUCCESS = "APPROVE_CONTENT_SUCCESS";
export const APPROVE_CONTENT_ERR = "APPROVE_CONTENT_ERR";


export const REWORK_CONTENT_REQUSET = "REWORK_CONTENT_REQUSET";
export const REWORK_CONTENT_SUCCESS = "REWORK_CONTENT_SUCCESS";
export const REWORK_CONTENT_ERR = "REWORK_CONTENT_ERR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERR = "CHANGE_PASSWORD_ERR";

export const USER_PROFILE_TOGGLE = "USER_PROFILE_TOGGLE";

export const DETERMINANT_REQUEST = "DETERMINANT_REQUEST";
export const DETERMINANT_SUCCESS = "DETERMINANT_SUCCESS";
export const DETERMINANT_ERR = "DETERMINANT_ERR";

export const DETERMINANT_CATOGORIES_REQUEST = "DETERMINANT_CATOGORIES_REQUEST";
export const DETERMINANT_CATOGORIES_SUCCESS = "DETERMINANT_CATOGORIES_SUCCESS";
export const DETERMINANT_CATOGORIES_ERR = "DETERMINANT_CATOGORIES_ERR";

export const DETERMINANT_CATOGORIES_PRACTICES_REQUEST = "DETERMINANT_CATOGORIES_PRACTICES_REQUEST";
export const DETERMINANT_CATOGORIES_PRACTICES_SUCCESS = "DETERMINANT_CATOGORIES_PRACTICES_SUCCESS";
export const DETERMINANT_CATOGORIES_PRACTICES_ERR = "DETERMINANT_CATOGORIES_PRACTICES_ERR";

export const TEACHER_LIST_REQUEST = "TEACHER_LIST_REQUEST";
export const TEACHER_LIST_SUCCESS = "TEACHER_LIST_SUCCESS";
export const TEACHER_LIST_ERR = "TEACHER_LIST_ERR";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERR = "GET_USER_ERR";

export const GET_CONTENT_USER_DETAIL_REQUEST = "GET_CONTENT_USER_DETAIL_REQUEST";
export const GET_CONTENT_USER_DETAIL_SUCCESS = "GET_CONTENT_USER_DETAIL_SUCCESS";
export const GET_CONTENT_USER_DETAIL_ERR = "GET_CONTENT_USER_DETAIL_ERR";

export const GET_USER_DETAIL_CONTENT_REQUEST = "GET_USER_DETAIL_CONTENT_REQUEST";
export const GET_USER_DETAIL_CONTENT_SUCCESS = "GET_USER_DETAIL_CONTENT_SUCCESS";
export const GET_USER_DETAIL_CONTENT_ERR = "GET_USER_DETAIL_CONTENT_ERR";

export const CREATE_CONTENT_ADMIN_REQUEST = "CREATE_CONTENT_ADMIN_REQUEST";
export const CREATE_CONTENT_ADMIN_SUCCESS = "CREATE_CONTENT_ADMIN_SUCCESS";
export const CREATE_CONTENT_ADMIN_ERR ="CREATE_CONTENT_ADMIN_ERR"; 

export const UPLOAD_FILES_AWS_REQUEST = "UPLOAD_FILES_AWS_REQUEST";
export const UPLOAD_FILES_AWS_SUCCESS = "UPLOAD_FILES_AWS_SUCCESS";
export const UPLOAD_FILES_AWS_ERR = "UPLOAD_FILES_AWS_ERR";

export const GET_PARTICULAR_DETERMINANT_REQUEST = "GET_PARTICULAR_DETERMINANT_REQUEST";
export const GET_PARTICULAR_DETERMINANT_SUCCESS = "GET_PARTICULAR_DETERMINANT_SUCCESS";
export const GET_PARTICULAR_DETERMINANT_ERR = "GET_PARTICULAR_DETERMINANT_ERR";

export const GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST = "GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST";
export const GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS = "GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS";
export const GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR = "GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR";



 