import React, { useState } from "react";
import "./login.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import TextBox from "../../atoms/textbox";
import sms from "../../assets/images/sms.png";
import passwordCheck from "../../assets/images/password-check.png";
import { Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import Cookies from 'js-cookie';


import { updateUserData } from "../../redux/action/user";
import { userLogin } from "../../redux/action/user";
import ButtonInput from "../../atoms/button";

const LoginSection = (props) => {
  const [rememberToggle, setRememberToggle] = useState(false);
  const [showLoader, setSowLoader] = useState(false);

  const navigate = useNavigate();
  const emailChange = (value) => {
    props.updateUserData({
      ...props.user,
      userName: value,
    });
  };

  const dashboardNavigation = () => {
    toast("Login success...");
    navigate("/dashboard");
    props.updateUserData({
      ...props.user,
      userName: Cookies.get('email') ? Cookies.get('email') : '',
      password: Cookies.get('password') ? Cookies.get('password') : '',
    });
  };

  const passwordChange = (value) => {
    props.updateUserData({
      ...props.user,
      password: value,
    });
  };

  const gotoDashboard =async () => {
    if (props?.user?.userName?.length === 0 || props?.user?.password?.length === 0) {
      toast("Please Enter Valid data!");
      return;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(props?.user?.userName)
    ) {
      toast("Please enter valid email!!");
      return false;
    } else if (props?.user?.password?.length <= 3) {
      toast("Password length min 4 char!!");
      return false;
    }

    if (rememberToggle) {
      Cookies.set('email', props?.user?.userName, 30);
      Cookies.set('password', props?.user?.password, 30);
    }
    setSowLoader(!showLoader);
    props.userLogin(
      props.user.userName,
      props.user.password,
      dashboardNavigation
      ); 
    };  


  const gotoForgotPass = () => {
    props.updateUserData({
      ...props.user,
      status: "LoginForgotPassword",
      isNewUser: true,
    });
  };

  return (
    <div className="login_form">
      <div className="container-fluid">
        <div className="row">
          <h1 className="title">Welcome Back!</h1>
          <p className="subTitle">Login as an admin User</p>
        </div>
        <TextBox
          placeholder={"Email Address"}
          value={props?.user?.userName}
          defaultValue={Cookies.get("email")}
          valueChange={emailChange}
          prefix={sms}
        />
        <TextBox
          placeholder={"Password"}
          type="password"
          value={props?.user?.password}
          defaultValue={Cookies.get("password")}
          valueChange={passwordChange}
          prefix={passwordCheck}
        />
        <div className="rememberForgotPasswordContainer">
          <div>
            <FormControlLabel control={<Checkbox defaultChecked={Cookies.get("email") ? true : false} onClick={() => setRememberToggle(!rememberToggle)} style={{ color: "#E29C2E" }} />}
              label="Remember me"
              className="cus-label"
            />
          </div>
          <div>
            <span className="cus-label" onClick={() => gotoForgotPass()}>
              Forgot Password
            </span>
          </div>
        </div>
        <div className="row m-0">
          <ButtonInput
            class="avenir-bold"
            text={
              showLoader && props.loginErr.name !== 'AxiosError' ?
              <CircularProgress size={25} color="success" />: "Login"
            }
            onSubmit={() => gotoDashboard()}
          />
        </div>   
        <div className="row text-center">
          <p className="white bottom-section">
            Unable to login? <span className="yellow-font">Help</span>
          </p>
        </div>
      </div>
    </div>
  );
};

LoginSection.propTypes = {};
LoginSection.defaultProps = {};

const actionCreators = {
  updateUserData,
  userLogin,
};

export default connect(
  ({ user }) => ({
    user,
    Login: user.login,
    loginLoading: user.loginLoading,
    loginErr: user.loginErr
  }),
  actionCreators
)(LoginSection);
