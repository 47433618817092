import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Option from "@mui/icons-material/MoreHorizOutlined";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Woman from "../../assets/images/woman.png";
import Like from "../../assets/images/likes-2.png";
import Comment from "../../assets/images/under-review.png";
import DisLike from "../../assets/images/dislikes.png";
import "./usermanagetable.scss";
import Avatar from '@mui/material/Avatar';
import { connect } from "react-redux";

import { showDetail, getAllUser, userDetail, getUserDetails } from "../../redux/action/user";

const UserManageTable = (props) => {
  useEffect(() => {
    props.getAllUser();
  }, []);

  const UserDetails = (userid, toggle, orgUserId) => {
    props.getUserDetails(orgUserId);
    props.userDetail(userid);
    props.showDetail(toggle);
  }

  useEffect(() => {
    localStorage.setItem('userRole', props?.role);
  }, [props?.role]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "#0f1c1e",
          border: "1px solid #273334",
          color: "#fff",
          "& th": {
            color: "#fff",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="headerBorder">
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Qualification</TableCell>
              <TableCell align="center">Experience</TableCell>
              <TableCell align="center">Total Contents</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="center">Quick Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ color: "#fff" }}>
            {props?.allUsers?.data?.map((row, index) => {
              if(row?.jp_contentuserroles[0]?.OrgRoleID === props.role && row?.UserID){
                return (
                  <TableRow
                    key={index}
                    onClick={() => UserDetails(row?.UserID, true, row?.jp_contentuserprofiles[0]?.OrgUserID)}
                    className="table-row"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& th": {
                        color: "#fff",
                        borderBottom: "1px solid #273334",
                      },
                      "& td": {
                        borderBottom: "1px solid #273334",
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="text-white"
                    >
                      <div className="d-flex overEffect align-items-center">
                      <Avatar className="mx-3" alt="Travis Howard" src={row?.jp_contentuserprofiles[0]?.Photo ? row?.jp_contentuserprofiles[0]?.Photo: Woman} />
                        <div>
                          <span className="nameText">
                            {row?.jp_contentuser?.Fullname ? row?.jp_contentuser?.Fullname : "Robot"}{" "}
                            <p className={row?.Status === "A" ? "user-active" : "user-inactive"}>
                              <p className="user-active-on"> </p>{row?.Status === "A" ? "Active": "Inactive"}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
  
                    <TableCell
                      align="center"
                      className="text-white qualification"
                    >
                      {row?.jp_contentuserprofiles[0]?.Expertise ? row?.jp_contentuserprofiles[0]?.Expertise : 'Therapist'}
                    </TableCell>
                    <TableCell align="center" className="expirence">
                      {row?.jp_contentuserprofiles[0]?.YearsOfExperience ? row?.jp_contentuserprofiles[0]?.YearsOfExperience : 0}
                    </TableCell>
                    <TableCell align="center" className="text-white totalContent">
                      Total
                    </TableCell>
                    <TableCell align="right" className="text-white">
                      <div>
                        <span className="user-table-like statushText">
                          {" "}
                          <img
                            src={Like}
                            alt="like"
                            width={18}
                            style={{ marginRight: "7px" }}
                          />
                          status
                        </span>
                        <span className="user-table-like statushText">
                          {" "}
                          <img
                            src={Comment}
                            alt="like"
                            width={18}
                            style={{ marginRight: "7px" }}
                          />
                          status
                        </span>
                        <span className="user-table-like statushText">
                          {" "}
                          <img
                            src={DisLike}
                            alt="like"
                            width={18}
                            style={{ marginRight: "7px" }}
                          />
                          Status
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="center" className="text-white">
                      <Option />
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {props?.allUserLoading && <h1>Loading...</h1>}
    </div>
  );
};

UserManageTable.propTypes = {};
UserManageTable.defaultProps = {};

const actionCreators = {
  showDetail,
  getAllUser,
  userDetail,
  getUserDetails
};

export default connect(
  ({ user }) => ({
    allUsers: user.allUser,
    allUserLoading: user.allUserLoading,
  }),
  actionCreators
)(UserManageTable);
