import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../redux';

const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__( {} ) : compose;

const enhancer = composeEnhancers( applyMiddleware( ...[ thunk ] ) );

const store = createStore( rootReducer, enhancer );

export default store;
