import React from 'react'
import './slidercard.scss'

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from "@mui/icons-material/StarRate";
import Man from '../../assets/images/man.png'
import YellowPlay from '../../assets/images/video-play-yellow.png'

const SliderCard = (props) => {
  const { rating, personName, profession, points } = props;
  const [starActive, setStarActive] = React.useState(true);
  return (
    <div className="slide-card-container">
      <div className='rating-1'>
        <span>

          {starActive ? (
            <StarBorderIcon
              style={{ width: "15px" }}
              onClick={() => {
                setStarActive(!starActive);
              }}
            />
          ) : (
            <StarRateIcon
              style={{ color: "yellow", width: "15px" }}
              onClick={() => {
                setStarActive(!starActive);
              }}
            />
          )}

        </span>
        <span className='rating-text'>{rating}</span>
      </div>

      <div className='slide-card'>
        <img src={Man} alt="man" width={50} height={50} />
        <h6>{personName}</h6>
        <span className='profession'>{profession}</span>
        <div className='rating-2'>
          <span>

            <img src={YellowPlay} className="mx-2" alt="YellowPlay" width={15} />
          </span>
          <h6>2,190</h6>
        </div>
      </div>
    </div>
  )
}

export default SliderCard
