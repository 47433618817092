import {
  SET_USER_DATA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERR,
  SHOW_DETAIL,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERR,

  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERR,
  GRIDE_TOGGLE,

  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCESS,
  FORGOT_PASSWORD_ERR,

  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,

  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERR,
  MODEL_TOGGLE,
  CONTANT_ACTIVE_TAB,
  DRAWER_TOGGLE,

  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,

  USER_PROFILE_TOGGLE,

  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERR,

  GET_USER_DETAIL_CONTENT_REQUEST,
  GET_USER_DETAIL_CONTENT_SUCCESS,
  GET_USER_DETAIL_CONTENT_ERR
} from "../../constants/ActionTypes";
import Cookies from "js-cookie";

const email = Cookies.get('email');
const password = Cookies.get('password');

const initialState = {
  login: {},
  loginLoading: false,
  loginErr: {},
  userName: email ? email : "",
  password: password ? password : "",
  status: "login",
  isNewUser: false,
  isLogin: true,
  shoeDetailToggle: false,
  sendEmailData: {},

  listGrideToggle: false,

  allUser: [],
  allUserLoading: false,
  allUserError: false,

  selectedUser: {},
  selectedUserLoading: false,
  selectedUserErr: {},

  forgotPass: {},
  forgotPassLoading: false,
  forgotPassErr: {},

  verifyUser: {},
  verifyUserLoading: false,
  verifyUserErr: {},

  modelToggle: {
    toggle: false,
    modelName: ''
  },

  contentActiveTabeName: '',
  drawerToggleData: '',

  changePassword: {},
  changePasswordLoading: false,
  changePasswordErr: {},

  userProfileModalToggle: {},

  currentUser: {},
  currentUserLoading: false,
  currentUserErr: {},

  deleteUser: {},
  deleteUserLoading: false,
  deleteUserErr: {},

  userDetail: {},
  userDetailLoading: false,
  userDetailErr: {}
};

const user = (state = initialState, action = {}) => {

  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.content,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        login: {},
        loginLoading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
        loginLoading: false
      };
    case LOGIN_ERR:
      return {
        ...state,
        loginErr: action.payload,
        loginLoading: false
      };
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailData: {},
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailData: action.payload,
      };
    case SEND_EMAIL_ERR:
      return {
        ...state,
        sendEmailData: action.payload,
      };
    case SHOW_DETAIL: {
      return {
        ...state,
        shoeDetailToggle: action.payload
      }
    }
    case GET_ALL_USER_REQUEST: {
      return {
        ...state,
        allUser: action.payload,
        allUserLoading: true
      }
    }
    case GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        allUser: action.payload,
        allUserLoading: false
      }
    }
    case GET_ALL_USER_ERR: {
      return {
        ...state,
        allUserError: action.payload,
        allUserLoading: true
      }
    }

    case GET_USER_DETAIL_REQUEST: {
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserLoading: true
      }
    }
    case GET_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserLoading: false
      }
    }
    case GET_USER_DETAIL_ERR: {
      return {
        ...state,
        selectedUserErr: action.payload,
        selectedUserLoading: true
      }
    }

    case FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        forgotPass: {},
        forgotPassLoading: true
      }
    }
    case FORGOT_PASSWORD_SUCESS: {
      return {
        ...state,
        forgotPass: action.payload,
        forgotPassLoading: false
      }
    }
    case FORGOT_PASSWORD_ERR: {
      return {
        ...state,
        forgotPass: action.payload,
        forgotPassLoading: true
      }
    }
    case VERIFY_USER_REQUEST: {
      return {
        ...state,
        verifyUser: {},
        verifyUserLoading: true
      }
    }
    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verifyUser: action.payload,
        verifyUserLoading: false
      }
    }
    case VERIFY_USER_ERR: {
      return {
        ...state,
        verifyUser: action.payload,
        verifyUserLoading: true
      }
    }

    case GRIDE_TOGGLE: {
      return {
        ...state,
        listGrideToggle: action.payload
      }
    }
    case MODEL_TOGGLE: {
      return {
        ...state,
        modelToggle: action.payload
      }
    }
    case CONTANT_ACTIVE_TAB: {
      return {
        ...state,
        contentActiveTabeName: action.payload
      }
    }
    case DRAWER_TOGGLE: {
      return {
        ...state,
        drawerToggleData: action.payload
      }
    }

    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePassword: action.payload,
        changePasswordLoading: true
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: action.payload,
        changePasswordLoading: false
      }
    }
    case CHANGE_PASSWORD_ERR: {
      return {
        ...state,
        changePasswordErr: {},
        changePasswordLoading: false,
      }
    }
    case USER_PROFILE_TOGGLE: {
      return {
        ...state,
        userProfileModalToggle: action.payload
      }
    }
    case GET_USER_REQUEST: {
      return {
        ...state,
        currentUser: action.payload,
        currentUserLoading: true
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload,
        currentUserLoading: false
      }
    }
    case GET_USER_ERR: {
      return {
        ...state,
        currentUser: {},
        currentUserLoading: false,
      }
    }
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        deleteUser: action.payload,
        deleteUserLoading: true
      }
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleteUser: action.payload,
        deleteUserLoading: false
      }
    }
    case DELETE_USER_ERR: {
      return {
        ...state,
        deleteUser: {},
        deleteUserLoading: false,
      }
    }
     case GET_USER_DETAIL_CONTENT_REQUEST: {
      return {
        ...state,
        userDetail: action.payload,
        userDetailLoading: true
      }
    }
    case GET_USER_DETAIL_CONTENT_SUCCESS: {
      return {
        ...state,
        userDetail: action.payload,
        userDetailLoading: false
      }
    }
    case GET_USER_DETAIL_CONTENT_ERR: {
      return {
        ...state,
        userDetailErr: {},
        userDetailLoading: false,
      }
    }
    default:
      return state;
  }
};

export default user;
