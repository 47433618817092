import {
  SET_USER_DATA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERR,
  SHOW_DETAIL,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERR,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERR,
  GRIDE_TOGGLE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCESS,
  FORGOT_PASSWORD_ERR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERR,
  MODEL_TOGGLE,
  CONTANT_ACTIVE_TAB,
  DRAWER_TOGGLE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,
  USER_PROFILE_TOGGLE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERR,
  GET_USER_DETAIL_CONTENT_REQUEST,
  GET_USER_DETAIL_CONTENT_SUCCESS,
  GET_USER_DETAIL_CONTENT_ERR,
} from "../../constants/ActionTypes";
import { toast } from "react-toastify";
import axios from "axios";
import { api } from "../../constants/api";

export function setUserData(content) {
  return {
    type: SET_USER_DATA,
    content,
  };
}

export const userProfileModalToggle = (toggle, modelData) => {
  return {
    type: USER_PROFILE_TOGGLE,
    payload: {
      toggle: toggle,
      modelData: modelData,
    },
  };
};

export const showDetail = (toggle) => {
  return {
    type: SHOW_DETAIL,
    payload: toggle,
  };
};

export const contentActiveTab = (name) => {
  return {
    type: CONTANT_ACTIVE_TAB,
    payload: name,
  };
};

export const modelToggle = (toggle, modelName) => {
  return {
    type: MODEL_TOGGLE,
    payload: {
      toggle: toggle,
      modelName: modelName,
    },
  };
};

export const drawerToggle = (data) => {
  return {
    type: DRAWER_TOGGLE,
    payload: data,
  };
};

export const grideListToggle = (data) => {
  return {
    type: GRIDE_TOGGLE,
    payload: data,
  };
};

export function updateUserData(obj) {
  return (dispatch) => {
    dispatch(setUserData(obj));
  };
}

export const userLogin = (name, password, callBackFunction1) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: [],
    });
    return axios
      .post(`${api}/login`, {
        email: name,
        password: password,
      })
      .then((res) => {
        if (
          res?.data?.status === "success" &&
          res?.data?.data?.contentUserRole?.OrgRoleID === 2
        ) {
          localStorage.setItem("authToken", res?.data?.data?.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("loginUser", JSON.stringify(res?.data?.data));
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
          callBackFunction1();
        } else {
          toast("Something went wrong!");
        }
      })
      .catch((err) => {
        toast(err?.response?.data?.message);
        dispatch({
          type: LOGIN_ERR,
          payload: err,
        });
      });
  };
};

export const sendEmail = (email, userName, closeTostSendEmail, role) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: SEND_EMAIL_REQUEST,
      payload: {},
    });
    return axios
      .post(
        `${api}/invite_user`,
        {
          fullname: userName,
          email: email,
          roleId: role,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch({
          type: SEND_EMAIL_SUCCESS,
          payload: res,
        });
        if (res.status === 201) {
          closeTostSendEmail();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast("user already exist!");
        }
        dispatch({
          type: SEND_EMAIL_ERR,
          payload: err,
        });
      });
  };
};

export const getAllUser = () => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_ALL_USER_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/get_all_users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USER_ERR,
          payload: err,
        });
      });
  };
};

export const getUserDetails = (id) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL_CONTENT_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/contents/get_content_by_user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_DETAIL_CONTENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DETAIL_CONTENT_ERR,
          payload: err,
        });
      });
  };
};

export const getUser = () => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/get_my_details`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_ERR,
          payload: err,
        });
      });
  };
};

export const UpdatePassword = (oldPassword, password, passwordConfirm) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
      payload: [],
    });
    return axios
      .post(
        `${api}/update_admin_password`,
        {
          oldPassword: oldPassword,
          password: password,
          passwordConfirm: passwordConfirm,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_PASSWORD_ERR,
          payload: err,
        });
      });
  };
};

export const forgotPassword = (email, alertMsg) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: {},
    });
    return axios
      .put(`${api}/sendOtp`, {
        email: email,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: FORGOT_PASSWORD_SUCESS,
            payload: res.data,
          });
          alertMsg();
        }
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD_ERR,
          payload: err,
        });
      });
  };
};

export const verifyUser = () => {
  return (dispatch) => {
    dispatch({
      type: VERIFY_USER_REQUEST,
      payload: {},
    });
    return axios
      .post(`${api}/verifyUser`, {
        email: "manoharga.wa@gmail.com",
        otp: 708904,
      })
      .then((res) => {
        dispatch({
          type: VERIFY_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERIFY_USER_ERR,
          payload: err,
        });
      });
  };
};

export const userDetail = (id) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL_REQUEST,
      payload: {},
    });
    return axios
      .get(`${api}/get/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_DETAIL_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DETAIL_ERR,
          payload: err,
        });
      });
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: DELETE_USER_REQUEST,
      payload: [],
    });
    return axios
      .post(
        `${api}/delete/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast("User deleted successfully");
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        toast("Something went wrong");
        dispatch({
          type: DELETE_USER_ERR,
          payload: err,
        });
      });
  };
};
