import React from "react";
import Hoc from "../../component/HOC";

const Help = () => {
    return(
        <Hoc activeMenu="Help">
        <div>
            <h1 style={{ color: 'white' }}>Here is Help page</h1>
        </div>
        </Hoc>
    )
}

export default Help;