import React from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';

const Hoc = (props) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar selected={props?.activeMenu === 'Contents detals' ? 'Contents' : props?.activeMenu} />
        <div className="col-10 position-relative">
          <Header activeMenu={props?.activeMenu} subTitle={props?.subTitle} onClick={props?.onClick} />
          <div className='main-contents pr-40'> {props?.children}</div>
        </div>
      </div>
    </div>
  )
}

export default Hoc;
