import React, { useState } from "react";
import "./otpSection.scss";
import { connect } from "react-redux";
import {toast} from 'react-toastify';
import TextBox from "../../atoms/textbox";
import ButtonInput from "../../atoms/button";
import backButton from "../../assets/images/arrow-left.svg";

import { updateUserData, verifyUser } from "../../redux/action/user";

const OtpSection = (props) => {
  const [enableButton, setEnableButton] = useState(true);

  const otpChange = (value) => {
    if (value.length > 4) {
      return;
    }

    props.updateUserData({
      ...props.user,
      otp: value,
    });

    if (value.length === 4) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  };

  const loginClick = () => {

   if (props.user.isNewUser) {
      props.updateUserData({
        ...props.user,
        status: "confirmPassword",
        isNewUser: false,
      });
    } else {
      alert("Is old User");
    }
  };

  const gotoForgotPassword = () => {
    props.updateUserData({
      ...props.user,
      status: "LoginForgotPassword",
      isNewUser: false,
    });
  };

  return (
    <div className="login_form">
      <div className="container-fluid">
        <div className="row">
          <h2 className="title">Don't Worry, We're here to help you!</h2>
          <p className="subTitle">
            Enter your the verfiction code, you recieved on your Email.
          </p>
        </div>
        <div>
          <TextBox
            placeholder="Verification code"
            value={props.user?.otp}
            valueChange={otpChange}
          />
        </div>
        <div>
          <ButtonInput
            text={props.user.isNewUser ? "VERIFY" : "SUBMIT"}
            onSubmit={loginClick}
          />
          <ButtonInput
            text="Back"
            class="back-button"
            onSubmit={gotoForgotPassword}
            iCon={backButton}
          />
        </div>
      </div>
    </div>
  );
};

OtpSection.propTypes = {};
OtpSection.defaultProps = {};

const actionCreators = {
  updateUserData,
  verifyUser
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(OtpSection);
