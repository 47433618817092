import React, {useState} from 'react';
import Drawer from '@mui/material/Drawer';
import { connect } from 'react-redux';
import { modelToggle, drawerToggle } from '../../redux/action/user';
import './SideComponentModel.scss';
import EmojiPicker from 'emoji-picker-react';

const SideCommentComponents = (props) => {
    const [emojitoggle, setEmojiToggle] = useState(false);
    const [emojiData, setEmojiData] = useState([]);

    const get_emojies = (data) => {
        setEmojiData(...emojiData, data);
    }

    return (
        <div>
            <React.Fragment>
                <Drawer
                    className="comments-pop-up"
                    anchor={'right'}
                    open={props?.drawerToggleData}
                    onClose={() => props.drawerToggle(false)}
                >
                    <div className='inner-comment-section'>
                        <div className='comment-head-wrap'>
                            <h4>
                                Comments (7)
                            </h4>
                            <div onClick={() => props.drawerToggle(false)} className='ab-close-comment-wrap'>

                                <img src={require("../../assets/images/close.png")} />

                            </div>
                        </div>
                        <hr />
                        <ul className='comment-ul'>
                            <li className='comment-li'>
                                <div className='comment-head-tile-wrap'>
                                    <div className='left-side-wrap'>
                                        <p>
                                            Comment 1
                                        </p>
                                    </div>
                                    <div className='right-side-wrap'>
                                        <p>
                                            • 1 day ago
                                        </p>
                                        <img src={require("../../assets/images/more.png")} />
                                    </div>
                                </div>
                                <div className='desc-wrap'>
                                    <p>
                                        A scholarly article or essay, usually written by a law student and published in a law review, analyzing a judicial decision and its context in the law.
                                    </p>
                                </div>
                            </li>
                            <hr />
                            <li className='comment-li'>
                                <div className='comment-head-tile-wrap'>
                                    <div className='left-side-wrap'>
                                        <p>
                                            Comment 2
                                        </p>
                                    </div>
                                    <div className='right-side-wrap'>
                                        <p>
                                            • 1 day ago
                                        </p>
                                        <img src={require("../../assets/images/more.png")} />
                                    </div>
                                </div>
                                <div className='desc-wrap'>
                                    <p>
                                        A scholarly article or essay, usually written by a law student and published in a law review, analyzing a judicial decision and its context in the law.
                                    </p>
                                </div>
                            </li>
                            <hr />
                            <li className='comment-li'>
                                <div className='comment-head-tile-wrap'>
                                    <div className='left-side-wrap'>
                                        <p>
                                            Comment 3
                                        </p>
                                    </div>
                                    <div className='right-side-wrap'>
                                        <p>
                                            • 1 day ago
                                        </p>
                                        <img src={require("../../assets/images/more.png")} />
                                    </div>
                                </div>
                                <div className='desc-wrap'>
                                    <p>
                                        A scholarly article or essay, usually written by a law student and published in a law review, analyzing a judicial decision and its context in the law.
                                    </p>
                                </div>
                            </li>
                            <hr />
                            <li className='comment-li'>
                                <div className='comment-head-tile-wrap'>
                                    <div className='left-side-wrap'>
                                        <p>
                                            Comment 4
                                        </p>
                                    </div>
                                    <div className='right-side-wrap'>
                                        <p>
                                            • 1 day ago
                                        </p>
                                        <img src={require("../../assets/images/more.png")} />
                                    </div>
                                </div>
                                <div className='desc-wrap'>
                                    <p>
                                        A scholarly article or essay, usually written by a law student and published in a law review, analyzing a judicial decision and its context in the law.
                                    </p>
                                </div>
                            </li>
                            <hr />

                        </ul>
                        <div className='ab-write-comment-section'>
                            <div className='inner-write-comment-section'>
                                <h4>
                                    Comments
                                </h4>
                                <div>
                                    {
                                        emojitoggle && <EmojiPicker onEmojiClick={(data) => get_emojies(data.emoji)} value />
                                    }
                                    <textarea id="w3review" value={emojiData} onChange={() => setEmojiToggle(emojiData)} className='w-100 cus-in-wrap' name="w3review" rows="4" placeholder='Type here...'></textarea>
                                    <div className='tag-comments-wrap'>
                                        <img onClick={() => setEmojiToggle(!emojitoggle)} src={require("../../assets/images/emoji-happy.png")} />
                                        <img src={require("../../assets/images/at.png")} />
                                    </div>
                                </div>
                                <a onClick={() => props.drawerToggle(false)} href="" className='common-anchor w-100'>ADD Comment</a>

                            </div>
                        </div>
                    </div>

                </Drawer>
            </React.Fragment>
        </div>
    );
}

SideCommentComponents.propTypes = {};
SideCommentComponents.defaultProps = {};

const actionCreators = {
    modelToggle,
    drawerToggle
};

export default connect(
    ({ user }) => ({
        modelToggleData: user.modelToggle,
        drawerToggleData: user.drawerToggleData
    }),
    actionCreators
)(SideCommentComponents);