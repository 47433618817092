import { TextField } from '@mui/material';
import React from 'react';
import './textbox.scss';

const TextBox = (props) => {
    return (
        <TextField
            type={props.type ? props.type : 'text'}
            onChange={(e) => props.valueChange && props.valueChange(e.target.value)}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            InputProps={{
                startAdornment: props.prefix ? <img src={props.prefix} alt={props.placeholder} /> : null
            }}
            sx={{
                '& input': { paddingLeft: (theme) => theme.spacing(1), color: 'white' },
                '& fieldset': { borderRadius: '10px', border: '0px' },
            }}
        />
    );
};

TextBox.propTypes = {};
TextBox.defaultProps = {};

export default TextBox;
