import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { connect } from 'react-redux';

const BreadcrumbsComponents = (props) => {

    return (
        <>
            {
                props.selectedUserLoading ? <p>...</p> : (
                    <Breadcrumbs separator={<NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />} aria-label="breadcrumb" style={{ color: "#9fa4a5", textTransform: "uppercase", fontSize: "30px", lineHeight: "0.6em" }}>
                        <Link underline="none" color="inherit" href="/user_management_list">
                            {
                                props?.selectedUserData?.contentUserRole?.OrgRoleID === 2 && "ADMIN"
                            }
                            {
                                props?.selectedUserData?.contentUserRole?.OrgRoleID === 3 && "TEACHER"
                            }
                        </Link>
                        <Link underline="none" color="white">
                            {props?.selectedUserData?.contentUser?.Fullname}
                        </Link>
                    </Breadcrumbs>
                )
            }
        </>
    )
}

BreadcrumbsComponents.propTypes = {};
BreadcrumbsComponents.defaultProps = {};

const actionCreators = {
};

export default connect(
    ({ user }) => ({
        selectedUserData: user.selectedUser,
        selectedUserLoading: user.selectedUserLoading
    }),
    actionCreators
)(BreadcrumbsComponents);