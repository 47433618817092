import React, { useState } from "react";
import "./ContentList.scss";
import "../../../pages/contents/contents.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import eyeIcon from "../../../assets/images/eye.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useNavigate } from "react-router";
import ListItem1 from "../../../assets/images/list-view-1.png";
import leftSettingImage from "../../../assets/images/profile-logo.png";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";
import { capitalizeFirstLowercaseRest } from "../../../constants/constant";

const ContentList = (props) => {
  const navigate = useNavigate();
  const [starActive, setStarActive] = useState(true);

  const contentDetail = (id) => {
    navigate("/content_details/" + id + "/" + props?.activeTab.toLowerCase());
  };

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  
  const TableRowComponent = (data) => {
    return (
      <TableRow
        onClick={() => contentDetail(data?.data?.ID)}
        className="dark-td-wrap"
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          backgroundColor: "#0e1b1d",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ padding: "0px", width: "9%" }}
        >
          <img
            src={
              data?.data?.Thumbnailfile ? data?.data?.Thumbnailfile : ListItem1
            }
          />
        </TableCell>
        <TableCell align="left" sx={{ width: "24%" }}>
          <div className="tile-table-description">
            <span>
              &bull;{" "}
              {data?.data?.jp_determinant?.Determinant
                ? data?.data?.jp_determinant?.Determinant
                : "Movements"}
            </span>
            <h6>{data?.data?.ContentTitle?.slice(0, 36)}</h6>
            <h6 className="title-second-line">
              {data?.data?.ContentTitle?.slice(37, 100)}
            </h6>
          </div>
        </TableCell>
        <TableCell align="start" sx={{ width: "20%" }}>
          <div className="avtarWrap">
            <div className="imageWrap">
              <Avatar
                alt="Travis Howard"
                src={
                  data?.data?.jp_contentorguser?.jp_contentuserprofiles[0]
                    ?.Photo
                }
              />
            </div>
            <div className="profileNameSetting">
              <p className="mb-0">Created by</p>
              <h6 className="avenir-bold mb-0 ">
                {capitalizeFirstLowercaseRest(
                  data?.data?.jp_contentorguser?.jp_contentuserprofiles[0]
                    ?.DisplayName
                )}
              </h6>
            </div>
          </div>
        </TableCell>
        <TableCell align="center" sx={{ width: "23.5%" }}>
          <div className="table-rate-view-wrap">
            <div className="bg-dark-tag">
              {starActive ? (
                <StarBorderIcon
                  style={{ color: "#8d9394" }}
                  onClick={() => {
                    setStarActive(!starActive);
                  }}
                />
              ) : (
                <StarRateIcon
                  style={{ color: "yellow" }}
                  onClick={() => {
                    setStarActive(!starActive);
                  }}
                />
              )}
              <p style={{ color: "white" }}>{data?.data?.overallrating}</p>
            </div>
            <div className="eye-count bg-dark-tag">
              <img src={eyeIcon} alt="eyeIcon" />
              <p style={{ marginLeft: "5px" }}>
                {kFormatter(data?.data?.numviews ? data?.data?.numviews : 0)}
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell align="right" sx={{ width: "23.5%" }}>
          {data?.data?.ContentCurrentStatus === "Approved" && (
            <div className="workStatusWrap">
              <p className="approved">APPROVED</p>
            </div>
          )}
          {data?.data?.ContentCurrentStatus === "Rework" && (
            <div className="workStatusWrap">
              <p className="rework">REWORK</p>
            </div>
          )}
          {data?.data?.ContentCurrentStatus === "Under Review" && (
            <div className="workStatusWrap">
              <p className="rework">UNDER REVIEW</p>
            </div>
          )}
          {data?.data?.ContentCurrentStatus === "Disapproved" && (
            <div className="workStatusWrap">
              <p className="disapproved">DISAPPROVED</p>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <TableContainer className="mt-0">
        <Table
          sx={{
            minWidth: 650,
            borderCollapse: "separate",
            borderSpacing: "0px 14px",
            "&:last-child": { borderSpacing: "0px 14px" },
          }}
          aria-label="simple table"
        >
          <TableBody>
            {props.allContentListLoading && (
              <h1 className="listLoader">Loading...</h1>
            )}
            {props?.data?.map((data, index) => {
              return (
                <>
                  {props?.activeTab === "all" && (
                    <TableRowComponent data={data} key={index} />
                  )}
                </>
              );
            })}

            {props?.data?.map((data, index) => {
              return (
                <>
                  {data?.ContentCurrentStatus === props?.activeTab && (
                    <TableRowComponent data={data} key={index} />
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

ContentList.propTypes = {};
ContentList.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ content }) => ({
    allContentListLoading: content.allContentListLoading,
  }),
  actionCreators
)(ContentList);
