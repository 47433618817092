import React, { useState, useEffect } from "react";
import {
  AppBar,
  Card,
  CardMedia,
  Typography,
  Grid,
  CardContent,
  Box,
  SvgIcon,
  IconButton,
} from "@mui/material";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CircleIcon from "@mui/icons-material/Circle";
import "./userdetails.scss";
import Man from "../../assets/images/woman.png";
import { connect } from "react-redux";
import SearchIcon from "../../assets/images/search-round.png";

import Menu from "../../assets/images/sort.png";
import Video from "../userDetailComponents/Video/Video";
import { showDetail } from "../../redux/action/user";
import { userProfileModalToggle, modelToggle } from "../../redux/action/user";
import Avatar from '@mui/material/Avatar';
import { AddIcCallTwoTone, AirplanemodeInactiveOutlined, FormatIndentDecreaseRounded, IcecreamOutlined, ImportContactsRounded, InsertCommentTwoTone, InsertEmoticonSharp, InvertColorsTwoTone, PictureInPictureAltOutlined, SettingsInputComponentRounded } from "@mui/icons-material";

const UserDetails = (props) => {
  const [activeTab, setActiveTab] = useState('About');
  const [userRole, setUserRole] = useState();

  const removeUser = () => {
    // alert('are you sure you want to delete!');
    props.modelToggle(true, "delete_user");
    // props.showDetail(false);
  }

  useEffect(() => {
    const user = localStorage.getItem('userRole');
    setUserRole(user);
  }, [localStorage.getItem('userRole')]);

  return (
    <>
      {
        props.selectedUserLoading && <h1>Loading...</h1>
      }
      {
        !props.selectedUserLoading && (
          <div className={props?.activePage === "UserProfileModal" ? "user-model-details-container" : "container-fluid  user-details-container p-0"}>
            <Card className="user-details-box">
              <div className="user-card">
                <div className="user-card-inner-1">
                  <CardMedia>
                    <Avatar alt="Travis Howard" src={props?.data?.contentUserProfile?.Photo ? props?.data?.contentUserProfile?.Photo : Man} />
                  </CardMedia>
                  <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                    {props?.data?.contentUser?.Fullname} <CircleIcon sx={props?.data?.contentUser?.Status === "A" ? { width: "10px", color: "#40CE79" } : { width: "10px", color: "red" }} />
                    <p className="sub-inner-child-p">{props?.data?.contentUserProfile?.Gender}  <b>. {props?.data?.contentUserProfile?.Expertise}</b></p>
                  </Typography>
                  <div className="user-card-contact">
                    <PhoneCallbackIcon sx={{ width: "18px" }} /><span>{props?.data?.contentUser?.CountryCode ? props?.data?.contentUser?.CountryCode : '+91'}</span> {props?.data?.contentUser?.MobileNo ? props?.data?.contentUser?.MobileNo : "0000000000"}
                  </div>
                </div>
                {
                  props?.activePage == "UserProfileModal" && (<div>
                    <img onClick={() => props.userProfileModalToggle(false, null)} src={require("../../assets/images/x.png")} style={{ cursor: 'pointer' }} />
                  </div>)}
                {
                  props?.activePage == "UserManagement" && (
                    <div>
                      <button
                        onClick={() => removeUser()}
                        className="avenir-bold editBtnProfile"
                      >
                        {" "}
                        <img src={require("../../assets/images/x.png")} alt="" /> Remove{" "}
                      </button>
                    </div>
                  )
                }
              </div>
              <AppBar
                position="relative"
                sx={{ backgroundColor: "#0f1c1e", padding: "10px" }}
              >
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <ul className="inner-user-ul-list">
                    <li onClick={() => setActiveTab('About')} className={activeTab === 'About' && "active-tabe"}>
                      <Grid item>
                        <svg
                          className="cus-svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.68 3.96C16.16 4.67 16.44 5.52 16.44 6.44C16.43 8.84 14.54 10.79 12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.61996 10.8 7.82996 9.11 7.58996 6.95C7.29996 4.38 9.40996 2 11.99 2"
                            stroke="#727A7B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.99005 14.56C4.57005 16.18 4.57005 18.82 6.99005 20.43C9.74005 22.27 14.25 22.27 17 20.43C19.42 18.81 19.42 16.17 17 14.56C14.27 12.73 9.76005 12.73 6.99005 14.56Z"
                            stroke="#727A7B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Grid>
                      <Grid item> About</Grid>
                    </li>
                    <li onClick={() => setActiveTab('Video')} className={activeTab === 'Video' && "active-tabe"}>
                      {
                        userRole === "3" && (
                          <Grid item>
                            <svg
                              className="cus-svg"
                              width="24"
                              height="24"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.6667 26.6668H9.33341C5.33341 26.6668 2.66675 24.0002 2.66675 20.0002V12.0002C2.66675 8.00016 5.33341 5.3335 9.33341 5.3335H22.6667C26.6667 5.3335 29.3334 8.00016 29.3334 12.0002V20.0002C29.3334 24.0002 26.6667 26.6668 22.6667 26.6668Z"
                                stroke="#727A7B"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.2 12.6669L18.5334 14.6669C19.7334 15.4669 19.7334 16.6669 18.5334 17.4669L15.2 19.4669C13.8667 20.2669 12.8 19.6002 12.8 18.1336V14.1336C12.8 12.4002 13.8667 11.8669 15.2 12.6669Z"
                                stroke="#727A7B"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Grid>
                        )
                      }
                      {
                        userRole === "3" && <Grid item onClick={() => setActiveTab('Video')}> Video</Grid>
                      }
                    </li>
                    <li onClick={() => setActiveTab('Analysis')} className={activeTab === 'Analysis' && "active-tabe"}>
                      <Grid item>
                        <svg
                          className="cus-svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.32 12.0002C20.92 12.0002 22 11.0002 21.04 7.72018C20.39 5.51018 18.49 3.61018 16.28 2.96018C13 2.00018 12 3.08018 12 5.68018V8.56018C12 11.0002 13 12.0002 15 12.0002H18.32Z"
                            stroke="#727A7B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.9999 14.6998C19.0699 19.3298 14.6299 22.6898 9.57993 21.8698C5.78993 21.2598 2.73993 18.2098 2.11993 14.4198C1.30993 9.38977 4.64993 4.94977 9.25993 4.00977"
                            stroke="#727A7B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Grid>
                      <Grid item> Analysis</Grid>
                    </li>
                  </ul>
                </Grid>
              </AppBar>
            </Card>
            <div>
              {
                activeTab === "About" && (<Grid container spacing={3} className="mt-2">

                  <Grid item xs={5}>
                    <Card
                      sx={{
                        backgroundColor: "#0f1c1e",
                        fontSize: "16px",
                        border: "1px solid #263234",
                        height: "100%",
                      }}
                    >

                      <CardContent
                        sx={{
                          borderBottom: "1px solid #263234",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              gap={1}
                              sx={{
                                color: "#727A7B",
                              }}
                            >
                              <div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.12 13.53C12.1 13.53 12.07 13.53 12.05 13.53C12.02 13.53 11.98 13.53 11.95 13.53C9.67998 13.46 7.97998 11.69 7.97998 9.50998C7.97998 7.28998 9.78998 5.47998 12.01 5.47998C14.23 5.47998 16.04 7.28998 16.04 9.50998C16.03 11.7 14.32 13.46 12.15 13.53C12.13 13.53 12.13 13.53 12.12 13.53ZM12 6.96998C10.6 6.96998 9.46998 8.10998 9.46998 9.49998C9.46998 10.87 10.54 11.98 11.9 12.03C11.93 12.02 12.03 12.02 12.13 12.03C13.47 11.96 14.52 10.86 14.53 9.49998C14.53 8.10998 13.4 6.96998 12 6.96998Z"
                                    fill="#727A7B"
                                  />
                                  <path
                                    d="M12 22.7498C9.30996 22.7498 6.73996 21.7498 4.74996 19.9298C4.56996 19.7698 4.48996 19.5298 4.50996 19.2998C4.63996 18.1098 5.37996 16.9998 6.60996 16.1798C9.58996 14.1998 14.42 14.1998 17.39 16.1798C18.62 17.0098 19.36 18.1098 19.49 19.2998C19.52 19.5398 19.43 19.7698 19.25 19.9298C17.26 21.7498 14.69 22.7498 12 22.7498ZM6.07996 19.0998C7.73996 20.4898 9.82996 21.2498 12 21.2498C14.17 21.2498 16.26 20.4898 17.92 19.0998C17.74 18.4898 17.26 17.8998 16.55 17.4198C14.09 15.7798 9.91996 15.7798 7.43996 17.4198C6.72996 17.8998 6.25996 18.4898 6.07996 19.0998Z"
                                    fill="#727A7B"
                                  />
                                  <path
                                    d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                                    fill="#727A7B"
                                  />
                                </svg>
                              </div>
                              <div>USER NAME</div>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                color: "#fff",
                                textTransform: 'capitalize'
                              }}
                            >
                              {props?.data?.contentUser?.Fullname}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent
                        sx={{
                          borderBottom: "1px solid #263234",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              gap={1}
                              sx={{
                                color: "#727A7B",
                              }}
                            >
                              <div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 2V5"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M16 2V5"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 9.08984H20.5"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M22 19C22 19.75 21.79 20.46 21.42 21.06C20.73 22.22 19.46 23 18 23C16.99 23 16.07 22.63 15.37 22C15.06 21.74 14.79 21.42 14.58 21.06C14.21 20.46 14 19.75 14 19C14 16.79 15.79 15 18 15C19.2 15 20.27 15.53 21 16.36C21.62 17.07 22 17.99 22 19Z"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M16.4399 19L17.4299 19.99L19.5599 18.02"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3 13.1V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17"
                                    stroke="#727A7B"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M11.9955 13.7002H12.0045"
                                    stroke="#727A7B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.29431 13.7002H8.30329"
                                    stroke="#727A7B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.29431 16.7002H8.30329"
                                    stroke="#727A7B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div>DATE OF BIRTH</div>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                color: "#fff",
                              }}
                            >
                              {props?.data?.contentUserProfile?.BirthDate ? props?.data?.contentUserProfile?.BirthDate : ""}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent
                        sx={{
                          borderBottom: "1px solid #263234",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              gap={1}
                              sx={{
                                color: "#727A7B",
                              }}
                            >
                              <div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z"
                                    fill="#727A7B"
                                  />
                                  <path
                                    d="M6.46982 22.59C6.32982 22.59 6.19982 22.57 6.05982 22.54C5.40982 22.39 4.90982 21.89 4.75982 21.24L4.40982 19.77C4.38982 19.68 4.31982 19.61 4.21982 19.58L2.56982 19.19C1.94982 19.04 1.45982 18.58 1.28982 17.97C1.11982 17.36 1.28982 16.7 1.73982 16.25L5.63982 12.35C5.79982 12.19 6.01982 12.11 6.23982 12.13C6.45982 12.15 6.65982 12.27 6.78982 12.46C7.77982 13.92 9.32982 14.91 11.0598 15.16C11.6998 15.27 12.3298 15.27 12.9198 15.16C14.6698 14.91 16.2198 13.92 17.2098 12.46C17.3298 12.27 17.5398 12.15 17.7598 12.13C17.9798 12.11 18.1998 12.19 18.3598 12.35L22.2598 16.25C22.7098 16.7 22.8798 17.36 22.7098 17.97C22.5398 18.58 22.0398 19.05 21.4298 19.19L19.7798 19.58C19.6898 19.6 19.6198 19.67 19.5898 19.77L19.2398 21.24C19.0898 21.89 18.5898 22.39 17.9398 22.54C17.2898 22.7 16.6198 22.47 16.1998 21.96L11.9998 17.13L7.79982 21.97C7.45982 22.37 6.97982 22.59 6.46982 22.59ZM6.08982 14.03L2.79982 17.32C2.70982 17.41 2.71982 17.51 2.73982 17.57C2.74982 17.62 2.79982 17.72 2.91982 17.74L4.56982 18.13C5.21982 18.28 5.71982 18.78 5.86982 19.43L6.21982 20.9C6.24982 21.03 6.34982 21.07 6.40982 21.09C6.46982 21.1 6.56982 21.11 6.65982 21.01L10.4898 16.6C8.78982 16.27 7.22982 15.36 6.08982 14.03ZM13.5098 16.59L17.3398 20.99C17.4298 21.1 17.5398 21.1 17.5998 21.08C17.6598 21.07 17.7498 21.02 17.7898 20.89L18.1398 19.42C18.2898 18.77 18.7898 18.27 19.4398 18.12L21.0898 17.73C21.2098 17.7 21.2598 17.61 21.2698 17.56C21.2898 17.51 21.2998 17.4 21.2098 17.31L17.9198 14.02C16.7698 15.35 15.2198 16.26 13.5098 16.59Z"
                                    fill="#727A7B"
                                  />
                                  <path
                                    d="M13.8901 12.8903C13.6301 12.8903 13.3201 12.8203 12.9501 12.6003L12.0001 12.0302L11.0501 12.5902C10.1801 13.1102 9.61014 12.8102 9.40014 12.6602C9.19014 12.5102 8.74014 12.0603 8.97014 11.0703L9.21014 10.0403L8.41014 9.30023C7.97014 8.86023 7.81014 8.33025 7.96014 7.85025C8.11014 7.37025 8.55014 7.03024 9.17014 6.93024L10.2401 6.75024L10.7501 5.63025C11.0401 5.06025 11.4901 4.74023 12.0001 4.74023C12.5101 4.74023 12.9701 5.07026 13.2501 5.64026L13.8401 6.82025L14.8301 6.94025C15.4401 7.04025 15.8801 7.38023 16.0401 7.86023C16.1901 8.34023 16.0301 8.87024 15.5901 9.31024L14.7601 10.1403L15.0201 11.0703C15.2501 12.0603 14.8001 12.5102 14.5901 12.6602C14.4801 12.7502 14.2401 12.8903 13.8901 12.8903ZM9.61014 8.39026L10.3001 9.08023C10.6201 9.40023 10.7801 9.94025 10.6801 10.3802L10.4901 11.1802L11.2901 10.7102C11.7201 10.4602 12.3001 10.4602 12.7201 10.7102L13.5201 11.1802L13.3401 10.3802C13.2401 9.93025 13.3901 9.40023 13.7101 9.08023L14.4001 8.39026L13.5301 8.24023C13.1101 8.17023 12.6901 7.86026 12.5001 7.48026L12.0001 6.50024L11.5001 7.50024C11.3201 7.87024 10.9001 8.19025 10.4801 8.26025L9.61014 8.39026Z"
                                    fill="#727A7B"
                                  />
                                </svg>
                              </div>
                              <div>ABOVE</div>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Above
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent
                        sx={{
                          borderBottom: "1px solid #263234",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              gap={1}
                              sx={{
                                color: "#727A7B",
                              }}
                            >
                              <div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0001 22.76C10.5201 22.76 9.03005 22.2 7.87005 21.09C4.92005 18.25 1.66005 13.72 2.89005 8.33C4.00005 3.44 8.27005 1.25 12.0001 1.25C12.0001 1.25 12.0001 1.25 12.0101 1.25C15.7401 1.25 20.0101 3.44 21.1201 8.34C22.3401 13.73 19.0801 18.25 16.1301 21.09C14.9701 22.2 13.4801 22.76 12.0001 22.76ZM12.0001 2.75C9.09005 2.75 5.35005 4.3 4.36005 8.66C3.28005 13.37 6.24005 17.43 8.92005 20C10.6501 21.67 13.3601 21.67 15.0901 20C17.7601 17.43 20.7201 13.37 19.6601 8.66C18.6601 4.3 14.9101 2.75 12.0001 2.75Z"
                                    fill="#727A7B"
                                  />
                                  <path
                                    d="M10.7499 13.7499C10.5599 13.7499 10.3699 13.6799 10.2199 13.5299L8.71994 12.0299C8.42994 11.7399 8.42994 11.2599 8.71994 10.9699C9.00994 10.6799 9.48994 10.6799 9.77994 10.9699L10.7499 11.9399L14.2199 8.46994C14.5099 8.17994 14.9899 8.17994 15.2799 8.46994C15.5699 8.75994 15.5699 9.23994 15.2799 9.52994L11.2799 13.5299C11.1299 13.6799 10.9399 13.7499 10.7499 13.7499Z"
                                    fill="#727A7B"
                                  />
                                </svg>
                              </div>
                              <div>ADDRESS</div>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                color: "#fff",
                              }}
                            >
                              {props?.data?.contentUserOrgAddresses?.AddressLine1 + " " + props?.data?.contentUserOrgAddresses?.AddressLine2 + " " + props?.data?.contentUserOrgAddresses?.Country + " " + props?.data?.contentUserOrgAddresses?.City + " " + props?.data?.contentUserOrgAddresses?.State}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={7}>
                    <Card
                      sx={{
                        backgroundColor: "#0f1c1e",
                        padding: "15px",
                        border: "1px solid #263234",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="h6"
                          color={"#727A7B"}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.12 13.53C12.1 13.53 12.07 13.53 12.05 13.53C12.02 13.53 11.98 13.53 11.95 13.53C9.67998 13.46 7.97998 11.69 7.97998 9.50998C7.97998 7.28998 9.78998 5.47998 12.01 5.47998C14.23 5.47998 16.04 7.28998 16.04 9.50998C16.03 11.7 14.32 13.46 12.15 13.53C12.13 13.53 12.13 13.53 12.12 13.53ZM12 6.96998C10.6 6.96998 9.46998 8.10998 9.46998 9.49998C9.46998 10.87 10.54 11.98 11.9 12.03C11.93 12.02 12.03 12.02 12.13 12.03C13.47 11.96 14.52 10.86 14.53 9.49998C14.53 8.10998 13.4 6.96998 12 6.96998Z"
                              fill="#727A7B"
                            />
                            <path
                              d="M12 22.7498C9.30996 22.7498 6.73996 21.7498 4.74996 19.9298C4.56996 19.7698 4.48996 19.5298 4.50996 19.2998C4.63996 18.1098 5.37996 16.9998 6.60996 16.1798C9.58996 14.1998 14.42 14.1998 17.39 16.1798C18.62 17.0098 19.36 18.1098 19.49 19.2998C19.52 19.5398 19.43 19.7698 19.25 19.9298C17.26 21.7498 14.69 22.7498 12 22.7498ZM6.07996 19.0998C7.73996 20.4898 9.82996 21.2498 12 21.2498C14.17 21.2498 16.26 20.4898 17.92 19.0998C17.74 18.4898 17.26 17.8998 16.55 17.4198C14.09 15.7798 9.91996 15.7798 7.43996 17.4198C6.72996 17.8998 6.25996 18.4898 6.07996 19.0998Z"
                              fill="#727A7B"
                            />
                            <path
                              d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                              fill="#727A7B"
                            />
                          </svg>
                          <span className="h6"> BIO </span>
                        </Typography>
                        <Typography
                          component="p"
                          sx={{
                            fontSize: "16px",
                            paddingLeft: "13px",
                            paddingRight: "10px",
                            marginLeft: "13px",
                            color: "#fff",
                          }}
                        >
                          {props?.data?.contentUserProfile?.BriefBackground ? props?.data?.contentUserProfile?.BriefBackground : "i am a Nutritionist with one goal:to amke healthy living simple"}
                        </Typography>
                      </Box>
                      <hr />
                      <Box>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="h6"
                          color={"#727A7B"}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.12 13.53C12.1 13.53 12.07 13.53 12.05 13.53C12.02 13.53 11.98 13.53 11.95 13.53C9.67998 13.46 7.97998 11.69 7.97998 9.50998C7.97998 7.28998 9.78998 5.47998 12.01 5.47998C14.23 5.47998 16.04 7.28998 16.04 9.50998C16.03 11.7 14.32 13.46 12.15 13.53C12.13 13.53 12.13 13.53 12.12 13.53ZM12 6.96998C10.6 6.96998 9.46998 8.10998 9.46998 9.49998C9.46998 10.87 10.54 11.98 11.9 12.03C11.93 12.02 12.03 12.02 12.13 12.03C13.47 11.96 14.52 10.86 14.53 9.49998C14.53 8.10998 13.4 6.96998 12 6.96998Z"
                              fill="#727A7B"
                            />
                            <path
                              d="M12 22.7498C9.30996 22.7498 6.73996 21.7498 4.74996 19.9298C4.56996 19.7698 4.48996 19.5298 4.50996 19.2998C4.63996 18.1098 5.37996 16.9998 6.60996 16.1798C9.58996 14.1998 14.42 14.1998 17.39 16.1798C18.62 17.0098 19.36 18.1098 19.49 19.2998C19.52 19.5398 19.43 19.7698 19.25 19.9298C17.26 21.7498 14.69 22.7498 12 22.7498ZM6.07996 19.0998C7.73996 20.4898 9.82996 21.2498 12 21.2498C14.17 21.2498 16.26 20.4898 17.92 19.0998C17.74 18.4898 17.26 17.8998 16.55 17.4198C14.09 15.7798 9.91996 15.7798 7.43996 17.4198C6.72996 17.8998 6.25996 18.4898 6.07996 19.0998Z"
                              fill="#727A7B"
                            />
                            <path
                              d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                              fill="#727A7B"
                            />
                          </svg>
                          <span className="h6"> SOCIAL MEDIA</span>
                        </Typography>
                        <Box
                          className="social-icon-user-details"
                          display="flex"
                          gap={2}
                          marginLeft="20px"
                          color={"white"}
                        >
                          <a class='social-icon-link instagram'
                            href={props?.data?.contentUserProfile?.Instagram ? props?.data?.contentUserProfile?.Instagram : 'https://www.instagram.com/'}
                            target='_blank'
                            aria-label='instagram'>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.0001 29.3332H20.0001C26.6667 29.3332 29.3334 26.6665 29.3334 19.9998V11.9998C29.3334 5.33317 26.6667 2.6665 20.0001 2.6665H12.0001C5.33341 2.6665 2.66675 5.33317 2.66675 11.9998V19.9998C2.66675 26.6665 5.33341 29.3332 12.0001 29.3332Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.9999 20.6668C18.5772 20.6668 20.6666 18.5775 20.6666 16.0002C20.6666 13.4228 18.5772 11.3335 15.9999 11.3335C13.4226 11.3335 11.3333 13.4228 11.3333 16.0002C11.3333 18.5775 13.4226 20.6668 15.9999 20.6668Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M23.5149 9.33317H23.5303"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>

                          <a class='social-icon-link youtube'
                            href={props?.data?.contentUserProfile?.YouTube
                              ? props?.data?.contentUserProfile?.YouTube
                              : 'https://www.youtube.com/'}
                            target='_blank'
                            aria-label='youtube'>
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.6667 26.6668H9.33341C5.33341 26.6668 2.66675 24.0002 2.66675 20.0002V12.0002C2.66675 8.00016 5.33341 5.3335 9.33341 5.3335H22.6667C26.6667 5.3335 29.3334 8.00016 29.3334 12.0002V20.0002C29.3334 24.0002 26.6667 26.6668 22.6667 26.6668Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.2 12.6669L18.5334 14.6669C19.7334 15.4669 19.7334 16.6669 18.5334 17.4669L15.2 19.4669C13.8667 20.2669 12.8 19.6002 12.8 18.1336V14.1336C12.8 12.4002 13.8667 11.8669 15.2 12.6669Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>

                          <a class='social-icon-link mail'
                            href={`mailto:${props?.data?.contentUser?.EmailID}?subject=${"Hello" || ""}&body=${"set body part here" || ""}`}
                            target='_blank'
                            aria-label='mail'>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 29 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.2442 26H7.75581C2.83256 26 0 23.1622 0 18.2297V8.77027C0 3.83784 2.83256 1 7.75581 1H17.1977C17.7507 1 18.2093 1.45946 18.2093 2.01351C18.2093 2.56757 17.7507 3.02703 17.1977 3.02703H7.75581C3.89814 3.02703 2.02326 4.90541 2.02326 8.77027V18.2297C2.02326 22.0946 3.89814 23.973 7.75581 23.973H21.2442C25.1019 23.973 26.9767 22.0946 26.9767 18.2297V11.473C26.9767 10.9189 27.4353 10.4595 27.9884 10.4595C28.5414 10.4595 29 10.9189 29 11.473V18.2297C29 23.1622 26.1674 26 21.2442 26Z"
                                fill="white"
                              />
                              <path
                                d="M14.2296 14C13.174 14 12.1058 13.6625 11.2889 12.9746L7.35529 9.72978C6.95313 9.39231 6.87773 8.78228 7.20449 8.36694C7.53124 7.9516 8.12189 7.87372 8.52404 8.21119L12.4576 11.456C13.4128 12.2478 15.0339 12.2478 15.9891 11.456L17.472 10.236C17.8742 9.89851 18.4774 9.9634 18.7916 10.3917C19.1183 10.8071 19.0555 11.4301 18.6408 11.7546L17.1578 12.9746C16.3535 13.6625 15.2853 14 14.2296 14Z"
                                fill="white"
                              />
                              <path
                                d="M24.5 9C22.0215 9 20 6.97846 20 4.5C20 2.02154 22.0215 0 24.5 0C26.9785 0 29 2.02154 29 4.5C29 6.97846 26.9785 9 24.5 9ZM24.5 2.07692C23.1708 2.07692 22.0769 3.17077 22.0769 4.5C22.0769 5.82923 23.1708 6.92308 24.5 6.92308C25.8292 6.92308 26.9231 5.82923 26.9231 4.5C26.9231 3.17077 25.8292 2.07692 24.5 2.07692Z"
                                fill="white"
                              />
                            </svg>
                          </a>

                          <a class='social-icon-link twitter'
                            href={props?.data?.contentUserProfile?.Twitter
                              ? props?.data?.contentUserProfile?.Twitter
                              : 'https://twitter.com/'}
                            target='_blank'
                            aria-label='twitter'>
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 29 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.6667 7.08532L20.3333 6.75199L21 5.41865L19.3573 5.88532C18.9309 5.48745 18.3972 5.22315 17.8223 5.12506C17.2473 5.02697 16.6562 5.09937 16.1219 5.33333C15.5877 5.56729 15.1336 5.95257 14.8157 6.44162C14.4979 6.93066 14.3302 7.50207 14.3333 8.08532V8.75199C11.974 9.23865 9.91067 7.95199 8 5.75199C7.66667 7.52977 8 8.8631 9 9.75199L7 9.41865C7.27 10.798 7.908 11.8853 9.66667 12.0853L8 12.752C8.66667 14.0853 9.71067 14.292 11.3333 14.4187C10.0713 15.3204 8.55069 15.7883 7 15.752C15.5033 19.5313 20.3333 13.976 20.3333 9.08532V8.19999L21.6667 7.08532Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21 22.3333H7.66667C3.66667 22.3333 1 19.6667 1 15.6667V7.66667C1 3.66667 3.66667 1 7.66667 1H21C25 1 27.6667 3.66667 27.6667 7.66667V15.6667C27.6667 19.6667 25 22.3333 21 22.3333Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>

                          <a class='social-icon-link linkedin'
                            href={props?.data?.contentUserProfile?.LinkedIn
                              ? props?.data?.contentUserProfile?.LinkedIn
                              : 'https://www.linkedin.com/'}
                            target='_blank'
                            aria-label='linkedin'>
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.3333 20H7V11.55H10.3333V20ZM16.3333 14.15C15.9797 14.15 15.6406 14.287 15.3905 14.5308C15.1405 14.7746 15 15.1052 15 15.45V20H11.6667V11.55H15V12.5152C15.7442 11.9003 16.6846 11.5567 17.66 11.5435C19.6347 11.5435 21 12.9735 21 15.6742V20H17.6667V15.45C17.6667 15.1052 17.5262 14.7746 17.2761 14.5308C17.0261 14.287 16.687 14.15 16.3333 14.15V14.15ZM10.3333 8.625C10.3333 8.94639 10.2356 9.26057 10.0524 9.5278C9.86931 9.79503 9.60902 10.0033 9.30447 10.1263C8.99993 10.2493 8.66482 10.2815 8.34152 10.2188C8.01822 10.1561 7.72124 10.0013 7.48816 9.77405C7.25507 9.54679 7.09633 9.25724 7.03203 8.94202C6.96772 8.6268 7.00072 8.30007 7.12687 8.00314C7.25301 7.70621 7.46664 7.45242 7.74072 7.27386C8.0148 7.0953 8.33703 7 8.66667 7C9.10869 7 9.53262 7.1712 9.84518 7.47595C10.1577 7.7807 10.3333 8.19402 10.3333 8.625Z"
                                stroke="white"
                                stroke-width="0.666667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.45 28H18.55C25.3 28 28 25.3 28 18.55V10.45C28 3.7 25.3 1 18.55 1H10.45C3.7 1 1 3.7 1 10.45V18.55C1 25.3 3.7 28 10.45 28Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                </Grid>)
              }
              {
                activeTab === "Video" && userRole === "3" && (
                  <div className="video-tabe-wrap">
                    <div class="menu-item-container pt-4 p-0">
                      <div>
                        <h5 class="common-title avenir-bold">Videos</h5>
                      </div>
                      <div class="user-details-pop-up-side ">
                        <img className="mx-3" src={SearchIcon} alt="SearchIcon" />
                        <img src={Menu} alt="menu" />
                      </div>
                    </div>
                    {
                          props?.userDetailList?.data?.content?.query?.count === 0 &&
                          <div className="row">
                            <div className="text-center">
                              <h4>No content uploaded</h4>
                            </div>
                          </div>
                        }
                    <Video data={props?.userDetailList?.data?.content?.query?.rows} /> :
                  </div>
                )
              }
              {
                activeTab === "Analysis" && (
                  <img src={require('../../assets/images/qr.png')} style={{ resize: 'cover' }} />
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
};

UserDetails.propTypes = {};
UserDetails.defaultProps = {};

const actionCreators = {
  showDetail,
  userProfileModalToggle,
  modelToggle
};

export default connect(
  ({ user, content }) => ({
    selectedUserData: user.selectedUser,
    selectedUserLoading: user.selectedUserLoading,
    userProfileModal: user.userProfileModalToggle,
    modelToggleData: user.modelToggle,
    userDetailList: user.userDetail,
    userDetailLoading: user.userDetailLoading
  }),
  actionCreators
)(UserDetails);
