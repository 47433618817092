import React, { useState } from "react";
import './Video.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ListItem1 from "../../../assets/images/list-view-1.png";
import Box from '@mui/material/Box';
import eyeIcon from "../../../assets/images/eye.png";
import leftSettingImage from "../../../assets/images/profile-logo.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRateIcon from "@mui/icons-material/StarRate";

const Video = (props) => {
    const [starActive, setStarActive] = useState(true);

    return (
        <Box>
            {
                props?.data?.map((data) => {
                    return (
                        <TableContainer className="mt-0">
                            <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: '0px 14px', '&:last-child': { borderSpacing: '0px 14px' }, }} aria-label="simple table">
                                <TableBody>
                                    <TableRow className="dark-td-wrap" sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "#0e1b1d" }}>
                                        <TableCell component="th" scope="row" sx={{ padding: "0px", width: "9%" }} >
                                            <img src={data?.Thumbnailfile ? data?.Thumbnailfile : ListItem1} />
                                        </TableCell>
                                        <TableCell align="left" sx={{ width: "24%" }}>
                                            <div className="tile-table-description">
                                                <span>
                                                    &bull;  {data?.jp_determinant?.Determinant}
                                                </span>
                                                <h6>
                                                    {data?.ContentTitle}
                                                </h6>
                                            </div>
                                        </TableCell>
                                        <TableCell align="start" sx={{ width: "20%" }}>
                                            <div className="table-rate-view-wrap">
                                                <div className="bg-dark-tag body-part-tag">

                                                    <p>
                                                        {data?.jp_category?.Category}
                                                    </p>
                                                </div>
                                                <div className=" bg-dark-tag body-part-tag">
                                                    <p>
                                                        {data?.jp_practice?.Practice}
                                                    </p>


                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" sx={{ width: "23.5%" }}>
                                            <div className="table-rate-view-wrap">
                                                <div className="bg-dark-tag">

                                                    {starActive ? (
                                                        <StarBorderIcon
                                                            style={{ color: "#8d9394" }}
                                                            onClick={() => {
                                                                setStarActive(!starActive);
                                                            }}
                                                        />
                                                    ) : (
                                                        <StarRateIcon
                                                            style={{ color: "yellow" }}
                                                            onClick={() => {
                                                                setStarActive(!starActive);
                                                            }}
                                                        />
                                                    )}
                                                    <p style={{ color: "white" }}>
                                                        {data?.overallrating ? data?.overallrating : 0}
                                                    </p>
                                                </div>
                                                <div className="eye-count bg-dark-tag">
                                                    <img src={eyeIcon} alt="eyeIcon" />
                                                    <p style={{ marginLeft: "5px" }}>
                                                        {data?.numviews}
                                                    </p>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right" sx={{ width: "23.5%", padding: "0px" }}>
                                            <div className="workStatusWrap video-tage-inner">
                                                {
                                                    data?.ContentCurrentStatus === "Approved" && (
                                                        <p className="approved">
                                                            &#8226; APPROVED
                                                            <span className="tri-left">

                                                            </span>
                                                        </p>
                                                    )
                                                }
                                                {
                                                    data?.ContentCurrentStatus === "Rework" && (
                                                        <p className="rework">
                                                            &#8226; rework
                                                            <span className="tri-left">

                                                            </span>
                                                        </p>
                                                    )
                                                }
                                                {
                                                    data?.ContentCurrentStatus === "Disapproved" && (
                                                        <p className="disapproved">
                                                            &#8226; Disapproved
                                                            <span className="tri-left">

                                                            </span>
                                                        </p>
                                                    )
                                                }
                                                 {
                                                    data?.ContentCurrentStatus === "Under Review" && (
                                                        <p className="under-review">
                                                            &#8226; Under Review
                                                            <span className="tri-left">

                                                            </span>
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {/* <TableRow className="dark-td-wrap" sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "#0e1b1d" }}>
                                        <TableCell component="th" scope="row" sx={{ padding: "0px", width: "9%" }} >
                                            <img src={ListItem1} />
                                        </TableCell>
                                        <TableCell align="left" sx={{ width: "24%" }}>
                                            <div className="tile-table-description">
                                                <span>
                                                    &bull;  Movements
                                                </span>
                                                <h6>
                                                    Weighted Lunges With Biceps Curls - 12 reps
                                                </h6>
                                            </div>
                                        </TableCell>
                                        <TableCell align="start" sx={{ width: "20%" }}>
                                            <div className="table-rate-view-wrap">
                                                <div className="bg-dark-tag body-part-tag">

                                                    <p>
                                                        Squats
                                                    </p>
                                                </div>
                                                <div className=" bg-dark-tag body-part-tag">
                                                    <p>
                                                        Lower body
                                                    </p>


                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" sx={{ width: "23.5%" }}>
                                            <div className="table-rate-view-wrap">
                                                <div className="bg-dark-tag">

                                                    {starActive ? (
                                                        <StarBorderIcon
                                                            style={{ color: "#8d9394" }}
                                                            onClick={() => {
                                                                setStarActive(!starActive);
                                                            }}
                                                        />
                                                    ) : (
                                                        <StarRateIcon
                                                            style={{ color: "yellow" }}
                                                            onClick={() => {
                                                                setStarActive(!starActive);
                                                            }}
                                                        />
                                                    )}
                                                    <p style={{ color: "white" }}>
                                                        4.5
                                                    </p>
                                                </div>
                                                <div className="eye-count bg-dark-tag">
                                                    <img src={eyeIcon} alt="eyeIcon" />
                                                    <p style={{ marginLeft: "5px" }}>
                                                        40
                                                    </p>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right" sx={{ width: "23.5%", padding: "0px" }}>
                                            <div className="workStatusWrap video-tage-inner">
                                                <p className="rework">
                                                    &#8226; rework
                                                    <span className="tri-left">

                                                    </span>
                                                </p>

                                            </div>
                                        </TableCell>
                                    </TableRow> */}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                })
            }
        </Box>
    )
}

export default Video;
