import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Hoc from "../../component/HOC";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import profileIcon from "../../assets/images/p-1.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import "./ContentDetail.scss";
import ModalVideo from 'react-modal-video';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import EyeGroup from "../../assets/images/eye-group.png";
import Star from "../../assets/images/star.svg";
import Calender from "../../assets/images/calender.svg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LinkTag from "../../component/LinkTag/LinkTag";
import ContentCard from "../../component/ContectComponets/ContentCard/index";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EngejmentChart from "../../component/ContectComponets/EngejmentChart/EngejmentChart";
import Moment from "moment";
import { connect } from "react-redux";

import DialogView from '../../component/Model/Dialog';
import SideCommentComponents from "../../component/Model/SideComponentModel";
import { modelToggle } from "../../redux/action/user";
import { getContentDetail, getContentUser } from "../../redux/action/content";
import { capitalizeFirstLowercaseRest } from "../../constants/constant";
import UserProfileModal from "../../component/userProfile/userProfileModal";
import { CircularProgress } from "@mui/material";
import { userProfileModalToggle } from "../../redux/action/user";
import moment from "moment";

const ContentDetails = (props) => {
    const { id } = useParams();
    const [videoModelToggle, setVideoModelToggle] = useState(false);

    const data = [
        {
            rate: 3.5,
            title: "Weighted Lunges With Biceps Curls - 12 reps",
            subtitle: "Natural",

        },
        {
            rate: 4,
            title: "Weighted Lunges With Biceps Curls - 12 reps",
            subtitle: "Natural",
        },
        {
            rate: 3,
            title: "Weighted Lunges With Biceps Curls - 12 reps",
            subtitle: "Natural",
        },
        {
            rate: 2,
            title: "Weighted Lunges With Biceps Curls - 12 reps",
            subtitle: "Natural",
        },


    ];

    const image = [
        EyeGroup,
        Star,
        Star,
        Calender
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getUsersDetailsAndProfileDetails(id)
    }, [id])

    useEffect(() => {
        getTeacherUserDetails()
    }, [props.contentDetailList])

    function getUsersDetailsAndProfileDetails(id) {
        props.getContentDetail(id);
    }

    function getTeacherUserDetails() {
        if (props?.contentDetailList?.data?.content?.jp_contentorguser?.UserID) {
            props.getContentUser(props?.contentDetailList?.data?.content?.jp_contentorguser?.UserID)
        }
    }



    const breadcrumbs = [
        <Link underline="none" key="1" color="#9fa4a5" href="/" fontSize={"13px"} display={"block"} className="avenir-semi">
            {props?.contentDetailList?.data?.content?.jp_category?.Category ? props?.contentDetailList?.data?.content?.jp_category?.Category : "Lower body"}
        </Link>,
        <Typography key="2" color="#9fa4a5" fontSize={"13px"} className="avenir-semi" lineHeight={"0px"}>
            {
                props?.contentDetailList?.data?.content?.jp_practice?.Practice ? props?.contentDetailList?.data?.content?.jp_practice?.Practice : 'Squats'
            }
        </Typography>
    ];
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        borderRight: "1px solid #3B5559",
        boxShadow: "none",
        borderRadius: "0px",
        color: "white"
    }));

    const handleClose = () => {
        props?.modelToggle(false, "edit_model");
    };

    const ViewProfile = (data) => {
        props.userProfileModalToggle(true, data)
    }
    const styles = {
        paperContainer: {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            height: '370px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url',
            backgroundImage: `url(${props?.contentDetailList?.data?.content?.Thumbnailfile})`,
            borderRadius: '7px'
        }
    };

    return (
        <Hoc activeMenu="Contents details" subTitle={"CONTENT GALLERY"}>
            <UserProfileModal />
            <Card className="contents-detals-wrap bg-dark br-grey rounded-3 mt-4" sx={{ display: "flex", padding: "20px 20px" }}>
                <DialogView open={props?.modelToggleData?.toggle} onClose={handleClose} />
                <SideCommentComponents open={props?.modelToggleData?.toggle} onClose={handleClose} />
                <Box sx={{ width: "30%", position: "relative" }}>
                    <div style={styles.paperContainer}>
                        {
                            props?.contentDetailloading && <CircularProgress sx={{ position: 'relative', zIndex: '100' }} size={25} color="success" />
                        }
                    </div>
                    <div className="ab-card-play-btn">
                        <React.Fragment>
                            <ModalVideo
                                channel='custom'
                                url={props?.contentDetailList?.data?.content?.OriginalURL}
                                autoplay
                                allowFullScreen={true}
                                ratio='16:9'
                                isOpen={videoModelToggle}
                                onClose={() => setVideoModelToggle(false)}
                            />
                        </React.Fragment>
                        {
                            !props?.contentDetailloading && <a
                                href="{modal}"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setVideoModelToggle(true);
                                }}
                            >
                                <PlayArrowIcon /> Play
                            </a>
                        }
                    </div>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", padding: "0px 0px 0px 25px", width: "70%" }}>
                    <CardContent className="mb-3 pb-10" sx={{ flex: "0 0 auto", padding: "0px" }}>
                        <Breadcrumbs separator="•" aria-label="breadcrumb" style={{ color: "#9fa4a5", textTransform: "uppercase", fontSize: "30px", lineHeight: "0.6em" }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </CardContent>
                    <h4 className="common-header fs-30 avenir-bold white pb-10">{props?.contentDetailList?.data?.content?.ContentTitle} </h4>
                    <p className="sub-title fs-17 pb-10">
                       Submitted on: {moment(props?.contentDetailList?.data?.content?.CreationDate).format('Do MMM, YYYY')}
                    </p>
                    <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >

                            {image.map((item, index) => {
                                return (
                                    <Grid className="contentd-ul-list pb-10" item xs={2} sm={4} md={3} key={index}>
                                        <Item className="contentd-list">
                                            <div>
                                                <img src={item} alt="React Logo" />
                                            </div>
                                            <div className="title-views">
                                                {
                                                    index === 0 && (
                                                        <>
                                                            <p>VIEWS</p> {props?.contentDetailList?.data?.content?.numviews}
                                                        </>
                                                    )
                                                }
                                                {
                                                    index === 1 &&
                                                    (
                                                        <>
                                                            <p>RATING</p> {props?.contentDetailList?.data?.content?.overallrating}
                                                        </>
                                                    )
                                                }
                                                {
                                                    index === 2 && (
                                                        <>
                                                            <p>DETERMINANT</p> {props?.contentDetailList?.data?.content?.jp_determinant?.Determinant}
                                                        </>
                                                    )
                                                }
                                                {
                                                    index === 3 && (
                                                        <>
                                                            <p>PUBLISH DATE</p> {props?.contentDetailList?.data?.content?.PublishedDate ? Moment(props?.contentDetailList?.data?.content?.PublishedDate).format('DD-MM-YYYY') : '...'}
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </Item>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <hr />
                    <Box sx={{
                        display: "flex", width: "100%", justifyContent: "space-between",
                        alignItems: "center"
                    }}>

                        <List sx={{ maxWidth: 360 }}>

                            <ListItem sx={{ padding: '0px' }}>
                                <ListItemAvatar>
                                    <Avatar alt="Travis Howard" src={props?.cuntentDetailUser?.data?.contentUserProfile?.Photo} />
                                </ListItemAvatar>
                                <ListItemText className="secendary" sx={{ color: 'white' }} primary={capitalizeFirstLowercaseRest(props?.cuntentDetailUser?.data?.contentUser?.Username ? props?.cuntentDetailUser?.data?.contentUser?.Username : '...')} secondary={capitalizeFirstLowercaseRest(props?.cuntentDetailUser?.data?.contentUserProfile?.Expertise ? props?.cuntentDetailUser?.data?.contentUserProfile?.Expertise : '...')} />
                            </ListItem>


                        </List>
                        <Button onClick={() => ViewProfile(props?.cuntentDetailUser?.data)} sx={{ color: "white", borderColor: "#4a5456" }} className="rounded-3" variant="outlined" size="large">
                            View Profile
                        </Button>
                    </Box>
                </Box>
            </Card>
            <Box className="rounded-3 bg-dark br-grey mt-4" sx={{ flexGrow: 2 }}>
                <Grid sx={{ minHeight: '400px' }} container spacing={2}>
                    <Grid xs={12} md={8} className="description-left-side">
                        <div className="border-right h-100 inn-des-left-wrap d-flex flex-column justify-content-center">
                            <h6 className="head-title-cus">
                                Description
                            </h6>
                            <p>
                                {
                                    // props?.contentDetailList?.data?.content?.Description?.slice(0, 1000)
                                    props?.contentDetailList?.data?.content?.Description
                                }
                            </p>
                            <h6 className="head-title-cus mt-4">
                                Reference Links
                            </h6>
                            <LinkTag data={props?.contentDetailList?.data?.content?.referenceLink1 ? props?.contentDetailList?.data?.content?.referenceLink1 : ''} />
                            <LinkTag data={props?.contentDetailList?.data?.content?.referenceLink2 ? props?.contentDetailList?.data?.content?.referenceLink2 : ''} />
                            <LinkTag data={props?.contentDetailList?.data?.content?.referenceLink3 ? props?.contentDetailList?.data?.content?.referenceLink3 : ''} />

                        </div>
                    </Grid>
                    <Grid xs={12} md={4} className="graph-right-side">
                        <EngejmentChart />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <h6 className="head-title-cus  mt-4 mb-3">
                    other content
                </h6>
                <div className="ContentWrapper">
                    {data.map((obj, index) => {
                        return (
                            <div key={index} className="ContentCardList">
                                <ContentCard singleObject={obj} />
                            </div>
                        );
                    })}
                </div>
            </Box>
        </Hoc>
    )
}

ContentDetails.propTypes = {};
ContentDetails.defaultProps = {};

const actionCreators = {
    modelToggle,
    getContentDetail,
    userProfileModalToggle,
    getContentUser
};

export default connect(
    ({ user, content }) => ({
        modelToggleData: user.modelToggle,
        contentDetailList: content.contentDetailList,
        contentDetailloading: content.contentDetailloading,
        cuntentDetailUser: content.cuntentDetailUser
    }),
    actionCreators
)(ContentDetails);