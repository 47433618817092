import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import AnalyticsContents from "../../component/AnalyticsContent";
import Hoc from "../../component/HOC";
import DonutChart from "../../component/SemiCircle";
import RightImage from "../../assets/images/review-bg-dashboard.png";
import "./dashboard.scss";
import { Button } from "@mui/material";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { connect } from "react-redux";
import { getUser } from "../../redux/action/user";
import { getUnderReviewCount } from "../../redux/action/content";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [change, setChange] = useState("week");

  useEffect(() => {
    props.getUser();
    props.getUnderReviewCount();
  }, []);

  const go_Under_review = () => {
    navigate("/content/" + "Under Review");
  };

  const getUnderReviewCounts = () => {
    if (props?.underReviewCount?.Result) {
      return props?.underReviewCount?.Result[3]?.Content_count;
    } else {
      return 0;
    }
  };

  return (
    <Hoc activeMenu="Dashboard">
      <div className="dashboard-container">
        <div className="header-container">
          <div className="left-item">
            <h5 className="avenir-bold">My Dashboard</h5>
            <p>Looks, Like you have more updates 💪</p>
          </div>
          <div>
            <div className="right-items ">
              <button
                onClick={() => setChange("week")}
                className={change === "week" ? "buttons buttons-2" : "buttons"}
              >
                Week
              </button>
              <button
                onClick={() => setChange("year")}
                className={change === "year" ? "buttons buttons-2" : "buttons"}
              >
                Year
              </button>
              <button
                style={{ margin: "0px" }}
                onClick={() => setChange("month")}
                className={change === "month" ? "buttons buttons-2" : "buttons"}
              >
                Month
              </button>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <div className="chart-item">
            <div className="inner-chart-item">
              <div>
                {" "}
                <span className="per-green">+30%</span> drastic growth
              </div>
            </div>
            <div className="chart-graph cus-svg-dash">
              {props?.underReviewCount?.Result && (
                <DonutChart
                  change={change}
                  counts={
                    props?.underReviewCount?.Result
                      ? props?.underReviewCount?.Result
                      : []
                  }
                />
              )}
            </div>
            <div className="chart-value">
              {props?.underReviewCount?.Result &&
                props?.underReviewCount?.Result?.map((counts, index) => (
                  <div key={index}>
                    {counts?.Status === "Approved" && (
                      <div className="chart-1">
                        <h1>{counts?.Content_count}</h1>
                        <span>APPROVED</span>
                        <div className="line-np"></div>
                      </div>
                    )}
                    {counts?.Status === "Under Review" && (
                      <div className="chart-1">
                        <h1>{counts?.Content_count}</h1>
                        <span>UNDER REVIEW</span>
                        <div className="line-np two"></div>
                      </div>
                    )}
                    {counts?.Status === "Disapproved" && (
                      <div className="chart-1">
                        <h1>{counts?.Content_count}</h1>
                        <span>DISAPPROVED</span>
                        <div className="line-np three"></div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="right-image">
            <img src={RightImage} alt="right-img" className="right-img" />
            <div className="review-item">Under Review</div>
            <div className="review-btn-item">
              <div>
                <h4 className="text-warning mt-3">
                  {getUnderReviewCounts()} Contents
                </h4>
                <h6>Waiting For Your Approval !</h6>
              </div>
              <div>
                <Button
                  onClick={() => go_Under_review()}
                  variant="contained"
                  className="btn extendWidth pl-30 pr-30 h-50"
                  style={{ display: "flex", margin: "0px !important" }}
                >
                  Review Now <EastRoundedIcon sx={{ marginLeft: "10px" }} />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <AnalyticsContents />
        </div>
      </div>
    </Hoc>
  );
};

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

const actionCreators = {
  getUser,
  getUnderReviewCount,
};

export default connect(
  ({ user, content }) => ({
    currentUser: user.currentUser,
    underReviewCount: content.underReviewCountList,
  }),
  actionCreators
)(Dashboard);
