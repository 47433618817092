import React, { useState, useEffect, Fragment } from "react";
import Hoc from "../../component/HOC/index";
import { useParams } from "react-router";
import ContentCard from '../../component/ContectComponets/ContentCard/index';
import "./contents.scss";

import { connect } from 'react-redux';
import activeAll from '../../assets/images/all_detail.png';
import disableAll from '../../assets/images/disableAll.png';
import ContentList from "../../component/ContectComponets/contentGride/ContentList";

import { contentActiveTab } from "../../redux/action/user";
import { getAllContents } from "../../redux/action/content";

const Contents = (props) => {
  const { Under_Review } = useParams();
  const [activeTab, setActiveTab] = useState(Under_Review);
  const [arrayData, setArrayData] = useState();

  useEffect(() => {
    props.contentActiveTab(activeTab);
    props.getAllContents();
  }, [activeTab]);

  const contentLength = () => {
    const dataList = props?.allContentList?.data?.rows?.filter((data) => data?.ContentCurrentStatus === activeTab);
    setArrayData(dataList?.length);
  }

  useEffect(() => {
    contentLength();
  }, [activeTab])

  return (
    <Hoc activeMenu="Contents" subTitle={props?.allContentListLoading ? '...': (activeTab === "all" ? "Total Contents: " + props?.allContentList?.data?.rows?.length : "Total Contents: " + arrayData)}>
      <div className="user-container user-container-add-space">
        <div className="user-menu-item">
          <ul>
            <li onClick={() => setActiveTab("all")} className={activeTab === "all" && "active-tabe"}>
              <span>
                <img
                  src={activeTab === "all" ? activeAll : disableAll}
                  alt="teacher"
                />
              </span>
              <p className="laberText">All</p>
            </li>
            <li onClick={() => setActiveTab("Approved")} className={activeTab === "Approved" && "active-tabe"}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={activeTab === "Approved" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M7.48047 18.35L10.5805 20.75C10.9805 21.15 11.8805 21.35 12.4805 21.35H16.2805C17.4805 21.35 18.7805 20.45 19.0805 19.25L21.4805 11.95C21.9805 10.55 21.0805 9.34997 19.5805 9.34997H15.5805C14.9805 9.34997 14.4805 8.84997 14.5805 8.14997L15.0805 4.94997C15.2805 4.04997 14.6805 3.04997 13.7805 2.74997C12.9805 2.44997 11.9805 2.84997 11.5805 3.44997L7.48047 9.54997" stroke="#727A7B" stroke-width="1.5" stroke-miterlimit="10" />
                  <path style={activeTab === "Approved" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M2.37988 18.3499V8.5499C2.37988 7.1499 2.97988 6.6499 4.37988 6.6499H5.37988C6.77988 6.6499 7.37988 7.1499 7.37988 8.5499V18.3499C7.37988 19.7499 6.77988 20.2499 5.37988 20.2499H4.37988C2.97988 20.2499 2.37988 19.7499 2.37988 18.3499Z" stroke="#727A7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <p className="laberText">APPROVED</p>
            </li>
            <li onClick={() => setActiveTab("Under Review")} className={activeTab === "Under Review" && "active-tabe"}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={activeTab === "Under Review" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M22 10.0005V13.0005C22 17.0005 20 19.0005 16 19.0005H15.5C15.19 19.0005 14.89 19.1505 14.7 19.4005L13.2 21.4005C12.54 22.2805 11.46 22.2805 10.8 21.4005L9.3 19.4005C9.14 19.1805 8.77 19.0005 8.5 19.0005H8C4 19.0005 2 18.0005 2 13.0005V8.00049C2 4.00049 4 2.00049 8 2.00049H14" stroke="#727A7B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Under Review" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M19.5 7.00049C20.8807 7.00049 22 5.8812 22 4.50049C22 3.11978 20.8807 2.00049 19.5 2.00049C18.1193 2.00049 17 3.11978 17 4.50049C17 5.8812 18.1193 7.00049 19.5 7.00049Z" stroke="#727A7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Under Review" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M15.9965 11H16.0054" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Under Review" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M11.9955 11H12.0045" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Under Review" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M7.99451 11H8.00349" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <p className="laberText">UNDER REVIEW</p>
            </li>
            <li onClick={() => setActiveTab("Rework")} className={activeTab === "Rework" && "active-tabe"}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={activeTab === "Rework" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M22 10.0005V13.0005C22 17.0005 20 19.0005 16 19.0005H15.5C15.19 19.0005 14.89 19.1505 14.7 19.4005L13.2 21.4005C12.54 22.2805 11.46 22.2805 10.8 21.4005L9.3 19.4005C9.14 19.1805 8.77 19.0005 8.5 19.0005H8C4 19.0005 2 18.0005 2 13.0005V8.00049C2 4.00049 4 2.00049 8 2.00049H14" stroke="#727A7B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Rework" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M19.5 7.00049C20.8807 7.00049 22 5.8812 22 4.50049C22 3.11978 20.8807 2.00049 19.5 2.00049C18.1193 2.00049 17 3.11978 17 4.50049C17 5.8812 18.1193 7.00049 19.5 7.00049Z" stroke="#727A7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Rework" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M15.9965 11H16.0054" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Rework" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M11.9955 11H12.0045" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path style={activeTab === "Rework" ? { stroke: '#e99f2c' } : { stroke: '#727A7B' }} d="M7.99451 11H8.00349" stroke="#727A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <p className="laberText">REWORK</p>
            </li>
            <li onClick={() => setActiveTab("Disapproved")} className={activeTab === "Disapproved" && "active-tabe"}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={activeTab === "Disapproved" ? { fill: '#e99f2c' } : { fill: '#727A7B' }} d="M10.8 22.0994C10.51 22.0994 10.22 22.0494 9.95004 21.9494C8.70004 21.5394 7.90004 20.1594 8.18004 18.8794L8.67004 15.7294C8.68004 15.6594 8.68004 15.5594 8.61004 15.4794C8.56004 15.4294 8.49004 15.3994 8.41004 15.3994H4.41004C3.43004 15.3994 2.58004 14.9894 2.08004 14.2794C1.59004 13.5894 1.49004 12.6794 1.81004 11.7994L4.20004 4.51941C4.57004 3.06941 6.12004 1.89941 7.72004 1.89941H11.52C12.08 1.89941 13.3 2.06941 13.95 2.71941L16.98 5.05941L16.06 6.24941L12.96 3.84941C12.71 3.59941 12.08 3.39941 11.52 3.39941H7.72004C6.82004 3.39941 5.85004 4.11941 5.65004 4.92941L3.23004 12.2794C3.07004 12.7194 3.10004 13.1194 3.31004 13.4094C3.53004 13.7194 3.93004 13.8994 4.42004 13.8994H8.42004C8.94004 13.8994 9.42004 14.1194 9.75004 14.4994C10.09 14.8894 10.24 15.4094 10.16 15.9494L9.66004 19.1594C9.54004 19.7194 9.92004 20.3494 10.46 20.5294C10.94 20.7094 11.58 20.4494 11.8 20.1294L15.9 14.0294L17.14 14.8694L13.04 20.9694C12.57 21.6694 11.68 22.0994 10.8 22.0994Z" fill="#727A7B" />
                  <path style={activeTab === "Disapproved" ? { fill: '#e99f2c' } : { fill: '#727A7B' }} d="M19.6201 18.1H18.6201C16.7701 18.1 15.8701 17.23 15.8701 15.45V5.65C15.8701 3.87 16.7701 3 18.6201 3H19.6201C21.4701 3 22.3701 3.87 22.3701 5.65V15.45C22.3701 17.23 21.4701 18.1 19.6201 18.1ZM18.6201 4.5C17.5301 4.5 17.3701 4.76 17.3701 5.65V15.45C17.3701 16.34 17.5301 16.6 18.6201 16.6H19.6201C20.7101 16.6 20.8701 16.34 20.8701 15.45V5.65C20.8701 4.76 20.7101 4.5 19.6201 4.5H18.6201Z" fill="#727A7B" />
                </svg>

              </span>
              <p className="laberText">DISAPPROVED</p>
            </li>
          </ul>
        </div>
      </div>
      {
        !props.listGrideToggle && (
          <ContentList data={props?.allContentList?.data?.rows} activeTab={activeTab} />
        )
      }
      {
        props.listGrideToggle && (
          <div className="ContentWrapper">
            {
              props?.allContentListLoading && (<h1 className="loading-font">Loading...</h1>)
            }
            <div className="ContentCardList">
              {
                props?.allContentList?.data?.rows?.map((obj, index) => {
                  return (
                    <Fragment key={index}>
                      {
                        obj?.ContentCurrentStatus === activeTab && <ContentCard activeTab={activeTab} singleObject={obj} />
                      }
                      {
                        activeTab === "all" && <ContentCard activeTab={activeTab} singleObject={obj} />
                      }
                    </Fragment>
                  );
                })
              }
            </div>

          </div>)
      }
    </Hoc>
  );
};

Contents.propTypes = {};
Contents.defaultProps = {};

const actionCreators = {
  contentActiveTab,
  getAllContents
};

export default connect(
  ({ user, content }) => ({
    listGrideToggle: user.listGrideToggle,
    allContentList: content.allContentList,
    allContentListLoading: content.allContentListLoading
  }),
  actionCreators
)(Contents);
