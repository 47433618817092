import React, { useState } from "react";
import {
    AppBar,
    Card,
    CardMedia,
    Typography,
    Grid,
} from "@mui/material";
import '../../component/UserDetails/userdetails.scss';
import './DeterminantsDetail.scss';
import CircleIcon from "@mui/icons-material/Circle";
import '../../component/Header/header.scss'
import Search from "../../assets/images/search.png";
import Man from '../../assets/images/woman.png';
import Hoc from "../../component/HOC";
import CategoriesTable from "../../component/Determinants/Categories/Categories";
import { useParams } from "react-router";
import { useEffect } from "react";
import { connect } from "react-redux";
import {getParticularDeterminant, getParticularDeterminantDetailsCount,determinantCategory} from "../../redux/action/content";

const DeterminantsDetail = (props) => {
    const [activeTab, setActiveTab] = useState('About');
    const { id } = useParams();
    const [categoriesList, setCategoriesList] = useState([])

    useEffect(()=>{
        props.getParticularDeterminant(id);
        props.getParticularDeterminantDetailsCount(id);
        props.determinantCategory(id)
    },[])

    useEffect(()=>{
        setCategoriesList(props?.determinantCategories?.data)
    },[props?.determinantCategories?.data])

    const filter = (e) => {
        let filterDataStore = [];
        for (var i = 0; i < props?.determinantCategories?.data.length; i++) {
            var txt = props?.determinantCategories?.data[i].Category; 
            if (txt.substring(0, e.target.value.length).toLowerCase() !== e.target.value.toLowerCase() && e.target.value.trim() !== "") {
            //   $(select.options[i]).attr('disabled', 'disabled').hide();
            } else {
                filterDataStore.push(props?.determinantCategories?.data[i])
                setCategoriesList(filterDataStore)
            }
        }
    }

    return (
        <Hoc activeMenu="Determinants" subTitle={"DETERMINATS > "+ props.getParticularDeterminantRes?.determinant?.Determinant.toUpperCase()}>
            <div className="container-fluid  user-details-container p-0 mt-4">
                <Card className="user-details-box">
                    <div className="user-card">
                        <div className="user-card-inner-1 w-100">
                            <div className="d-flex align-items-center gap-2">
                                <CardMedia>
                                    <img
                                        src={Man}
                                        alt="man"
                                        width={40}
                                        height={40}
                                        className="user-card-img"
                                    />
                                </CardMedia>
                                <Typography variant="h6">
                                    {props?.getParticularDeterminantRes?.determinant?.Determinant} <CircleIcon sx={{ width: "10px", color: "#40CE79" }} />
                                    <p className="sub-inner-child-p">{props?.getParticularDeterminantRes?.determinant?.Description} </p>
                                </Typography>
                            </div>

                            <Typography sx={{ display: "flex", gap: "20px" }}>
                                <div className="d-flex align-items-start gap-2">
                                    <img src={require('../../assets/images/hierarchy.png')} />
                                    <div>
                                        <p className="sub-inner-child-p">CATEGORIES</p>
                                        <span>{props.determinantCategories?.data?.length ? props.determinantCategories?.data?.length : 0}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start gap-2">
                                    <img src={require('../../assets/images/hierarchy-square.png')} />
                                    <div>
                                        <p className="sub-inner-child-p">SUB-CATEGORIES</p>
                                        <span>25</span>
                                    </div>
                                </div>
                            </Typography>
                        </div>
                    </div>
                    <AppBar
                        position="relative"
                        sx={{ backgroundColor: "#0f1c1e", padding: "10px" }}
                    >
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <ul className="inner-user-ul-list">
                                <li onClick={() => setActiveTab('About')} className={activeTab === 'About' && "active-tabe"}>
                                    <Grid item>
                                        <svg
                                            className="cus-svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.68 3.96C16.16 4.67 16.44 5.52 16.44 6.44C16.43 8.84 14.54 10.79 12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.61996 10.8 7.82996 9.11 7.58996 6.95C7.29996 4.38 9.40996 2 11.99 2"
                                                stroke="#727A7B"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6.99005 14.56C4.57005 16.18 4.57005 18.82 6.99005 20.43C9.74005 22.27 14.25 22.27 17 20.43C19.42 18.81 19.42 16.17 17 14.56C14.27 12.73 9.76005 12.73 6.99005 14.56Z"
                                                stroke="#727A7B"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </Grid>
                                    <Grid item> About</Grid>
                                </li>
                                <li onClick={() => setActiveTab('Category')} className={activeTab === 'Category' && "active-tabe"}>
                                    <Grid item>
                                        {
                                            activeTab === 'Category' ? (<img src={require('../../assets/images/activeCategory.png')} />) : (<img src={require('../../assets/images/category.png')} />)
                                        }
                                    </Grid>
                                    <Grid item onClick={() => setActiveTab('Video')}> CATEGIRIES</Grid>
                                </li>
                            </ul>
                        </Grid>
                    </AppBar>
                </Card>
                {
                    activeTab === "About" && (
                        <ul className="movements-ul">
                            <li>
                                <img src={require('../../assets/images/total-contents.png')} />
                                <p>
                                    Total Contents
                                </p>
                                <h3>
                                    {props?.getDeterminantsCount?.NoOfContents ? props?.getDeterminantsCount?.NoOfContents : 0}
                                </h3>
                            </li>
                            <li>
                                <img src={require('../../assets/images/disableLike.png')} />
                                <p>
                                    Approved
                                </p>
                                <h3>
                                {props?.getDeterminantsCount?.Approved ? props?.getDeterminantsCount?.Approved : 0}
                                </h3>
                            </li>
                            <li>
                                <img src={require('../../assets/images/disableMsg.png')} />
                                <p>
                                    Under Review
                                </p>
                                <h3>
                                    {props?.getDeterminantsCount?.UnderReview ? props?.getDeterminantsCount?.UnderReview : 0}
                                </h3>
                            </li>
                            <li>
                                <img src={require('../../assets/images/disableUnlike.png')} />
                                <p>
                                    Disapproved
                                </p>
                                <h3>
                                    {props?.getDeterminantsCount?.Disapproved + props?.getDeterminantsCount?.Rework ? props?.getDeterminantsCount?.Disapproved + props?.getDeterminantsCount?.Rework: 0}
                                </h3>
                            </li>
                        </ul>
                    )
                }
                {
                    activeTab === "Category" && (
                        <div className="mt-4">
                            <div className="gap-between mb-4">
                                <img className="search-bar-icon" src={Search} alt="search " />
                                <span className="op-5">|</span>
                                <input
                                    type="text"
                                    placeholder="Search here..."
                                    className="input-search w-100"
                                    onKeyUp={(e)=>filter(e)}
                                />
                            </div>
                            <CategoriesTable determinantCategories={categoriesList} />
                        </div>
                    )
                }
            </div>
        </Hoc>
    )
}



DeterminantsDetail.propTypes = {};
DeterminantsDetail.defaultProps = {};

const actionCreators = {
    getParticularDeterminant,
    getParticularDeterminantDetailsCount,
    determinantCategory
};

export default connect(
    ({ content }) => ({
        getParticularDeterminantRes: content.getParticularDeterminantRes,
        getDeterminantsCount: content.getParticularDeterminantDetailcountRes,
        determinantCategories: content.determinantCategories
    }),
    actionCreators
)(DeterminantsDetail);