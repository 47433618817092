import React, { useState } from "react";
import ColumnChart from "../ColumnChart";
import DashboardModalChart from "../DashboardModalChart";
import SimpleSlider from "../Slider";
import TopContentCard from "../TopContentCard";
import "./analytics.scss";
import Modal from "@mui/material/Modal";
import { Fade, Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Down from "../../assets/images/down.png";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const AnalyticsContents = () => {
  const [open, setOpen] = React.useState(false);
  const [topContentButtonToggle, setTopContentButtonToggle] = useState('view')

  const breadcrumbs = [
    <Link sx={{ textTransform: "uppercase",fontFamily: "avenir-bold" }} underline="none" key="1" color="inherit" href="/" onClick={handleClick}>
      Movement
    </Link>,
    
    <Typography sx={{ textTransform: "uppercase",fontFamily: "avenir-bold"}} key="2" color="text.primary">
      Shoulder
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1150,
    height: 550,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 0,
    borderRadius: '15px',
    outline: "0px"
  };

  return (
    <div className="analytics-wraper">
      <div className="analytics-container">
        <div className="analytics-1 position-relative">
          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box className="chart-full-wrap" sx={style}>
                <div className="chart-title-container">
                  <h3 className="avenir-bold">Movement -20/40</h3>
                  <img onClick={() => setOpen(false)} className="close-icon" src={require('../../assets/images/close.png')} />
                </div>
                <div className="out-box-chart-container h-100">
                  <div className="row m-0" style={{ height: "88.5%" }}>
                    <div className="col-md-3 p-0">
                      <ul className="list-chart-ul">
                        <li>
                          <div className="left-side">
                            <p>
                              Head
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Neck
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p className="avenir-bold">
                              Shoulder
                            </p>
                          </div>
                          <div className="right-side">
                            <p className="avenir-bold">
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Hands
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Plams & Fingers
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>

                        <li>
                          <div className="left-side">
                            <p>
                              Back
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>

                        <li>
                          <div className="left-side">
                            <p>
                              Pelvics
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Knee
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Legs
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="left-side">
                            <p>
                              Foot
                            </p>
                          </div>
                          <div className="right-side">
                            <p>
                              2/4 <ArrowForwardIosIcon />
                            </p>
                          </div>
                        </li>

                      </ul>
                    </div>
                    <div className="col-md-9 chart-slide-wrap">
                      <div className="ab-title-modal-chart position-relative">
                        <Stack spacing={2} sx={{ position: "absolute", top: "14px",left: "20px", zIndex:"1000" }} >
                          <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                          >
                            {breadcrumbs}
                          </Breadcrumbs>
                        </Stack>
                      </div>
                      <DashboardModalChart />
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
          <div onClick={() => setOpen(true)} className="full-mode-chart">
            <img
              src={require("../../assets/images/maximize.png")}
              width={24}
              height={24}
            />
          </div>
          <ColumnChart />
        </div>
        <div className="analytics-2 p-4">
          <div className="d-flex justify-content-between pb-4">
            <div>Top Teachers</div>
            <span className="f-12">
              Approved
              <img
                style={{ marginLeft: "10px" }}
                src={Down}
                alt="Down"
                width={12}
              />
            </span>
          </div>
          <SimpleSlider />
        </div>
        <div className="analytics-3">
          <div className="top-contents">
            <div className="top-text">
              <h1>Top Contents</h1>
            </div>
            <div className="rating-button">
              <button
                className={
                  topContentButtonToggle === "view" ? "rating-button-1 rating-button-2" : "rating-button-1"
                }
                onClick={() => setTopContentButtonToggle('view')}
              >
                Views
              </button>
              <button className={topContentButtonToggle === "rating" ? "rating-button-1 rating-button-2" : "rating-button-1"} onClick={() => setTopContentButtonToggle('rating')}> Rating</button>
            </div>
          </div>
          <div className="inner-scroll-wrap">
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
            <TopContentCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsContents;
