import {
    ALL_CONTENT_LIST_REQUEST,
    ALL_CONTENT_LIST_ERR,
    ALL_CONTENT_LIST_SUCCESS,
    GET_CONTENT_DETAIL_REQUEST,
    GET_CONTENT_DETAIL_SUCCESS,
    GET_CONTENT_DETAIL_ERR,
    GET_CONTENTTYPE_COUNT_REQUEST,
    GET_CONTENTTYPE_COUNT_SUCCESS,
    GET_CONTENTTYPE_COUNT_ERR,
    DIS_APPRUVE_DISAPPRUVE_REQUEST,
    DIS_APPRUVE_DISAPPRUVE_SUCCESS,
    DIS_APPRUVE_DISAPPRUVE_ERR,
    APPROVE_CONTENT_REQUSET,
    APPROVE_CONTENT_SUCCESS,
    APPROVE_CONTENT_ERR,
    REWORK_CONTENT_REQUSET,
    REWORK_CONTENT_SUCCESS,
    REWORK_CONTENT_ERR,
    DETERMINANT_REQUEST,
    DETERMINANT_SUCCESS,
    DETERMINANT_ERR,
    DETERMINANT_CATOGORIES_REQUEST,
    DETERMINANT_CATOGORIES_SUCCESS,
    DETERMINANT_CATOGORIES_ERR,
    DETERMINANT_CATOGORIES_PRACTICES_REQUEST,
    DETERMINANT_CATOGORIES_PRACTICES_SUCCESS,
    DETERMINANT_CATOGORIES_PRACTICES_ERR,

    GET_CONTENT_USER_DETAIL_REQUEST,
    GET_CONTENT_USER_DETAIL_SUCCESS,
    GET_CONTENT_USER_DETAIL_ERR,
    TEACHER_LIST_REQUEST,
    TEACHER_LIST_SUCCESS,
    TEACHER_LIST_ERR,
    CREATE_CONTENT_ADMIN_REQUEST,
    CREATE_CONTENT_ADMIN_SUCCESS,
    CREATE_CONTENT_ADMIN_ERR,
    UPLOAD_FILES_AWS_REQUEST,
    UPLOAD_FILES_AWS_SUCCESS,
    UPLOAD_FILES_AWS_ERR,
    GET_PARTICULAR_DETERMINANT_ERR,
    GET_PARTICULAR_DETERMINANT_SUCCESS,
    GET_PARTICULAR_DETERMINANT_REQUEST,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS,
    GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR
} from "../../constants/ActionTypes";

const initialState = {
    allContentList: {},
    allContentListLoading: false,
    allContentListErr: {},

    contentDetailList: {},
    contentDetailloading: false,
    contentDetailErr: {},

    contentDisappruved: {},
    contentDisappruvedLoading: false,
    contentDisappruvedErr: {},

    contentAppruved: {},
    contentAppruvedLoading: false,
    contentAppruvedErr: {},

    contentRework: {},
    contentReworkLoading: false,
    contentReworkErr: {},

    determinant: {},
    determinantLoading: false,
    determinantErr: {},

    determinantCategories: {},
    determinantCategoriesLoading: false,
    determinantCategoriesErr: {},

    determinantCategoriesPractices: {},
    determinantCategoriesPracticesLoading: false,
    determinantCategoriesPracticesErr: {},

    teachersList:{},
    teachersListLoading: false,
    teachersListErr: {},

    cuntentDetailUser: {},
    cuntentDetailUserLoading: false,
    cuntentDetailUserErr: {}
}

const content = (state = initialState, action = {}) => {
    switch (action.type) {
        case ALL_CONTENT_LIST_REQUEST: {
            return {
                ...state,
                allContentList: action.payload,
                allContentListLoading: true
            }
        }
        case ALL_CONTENT_LIST_SUCCESS: {
            return {
                ...state,
                allContentList: action.payload,
                allContentListLoading: false
            }
        }
        case ALL_CONTENT_LIST_ERR: {
            return {
                ...state,
                allContentList: action.payload,
                allContentListLoading: true
            }
        }
        case GET_CONTENT_DETAIL_REQUEST: {
            return {
                ...state, 
                contentDetailList: action.payload,
                contentDetailloading: true
            }
        }
        case GET_CONTENT_DETAIL_SUCCESS: {
            return {
                ...state,
                contentDetailList: action.payload,
                contentDetailloading: false
            }
        }
        case GET_CONTENT_DETAIL_ERR: {
            return {
                ...state,
                contentDetailErr: action.payload,
                contentDetailloading: false
            }
        }

        case GET_CONTENTTYPE_COUNT_REQUEST: {
            return {
                ...state, 
                underReviewCountList: action.payload,
                underReviewCountloading: true
            }
        }
        case GET_CONTENTTYPE_COUNT_SUCCESS: {
            // console.log(action.payload )
            return {
                ...state,
                underReviewCountList: action.payload,
                underReviewCountloading: false
            }
        }
        case GET_CONTENTTYPE_COUNT_ERR: {
            return {
                ...state,
                underReviewCountErr: action.payload,
                underReviewCountloading: false
            }
        }

        case DIS_APPRUVE_DISAPPRUVE_REQUEST: {
            return {
                ...state,
                contentDisappruved: action.payload,
                contentDisappruvedLoading: true
            }
        }
        case DIS_APPRUVE_DISAPPRUVE_SUCCESS: {
            return {
                ...state,
                contentDisappruved: action.payload,
                contentDisappruvedLoading: false
            }
        }
        case DIS_APPRUVE_DISAPPRUVE_ERR: {
            return {
                ...state,
                contentDisappruvedErr: action.payload,
                contentDisappruvedLoading: true
            }
        }
        case APPROVE_CONTENT_REQUSET: {
            return {
                ...state,
                contentAppruved: action.payload,
                contentAppruvedLoading: true,
            }
        }
        case APPROVE_CONTENT_SUCCESS: {
            return {
                ...state,
                contentAppruved: action.payload,
                contentAppruvedLoading: false,
            }
        }
        case APPROVE_CONTENT_ERR: {
            return {
                ...state,
                contentAppruvedErr: action.payload,
                contentAppruvedLoading: true
            }
        }
        case REWORK_CONTENT_REQUSET: {
            return {
                ...state,
                contentRework: action.payload,
                contentReworkLoading: true,
            }
        }
        case REWORK_CONTENT_SUCCESS: {
            return {
                ...state,
                contentRework: action.payload,
                contentReworkLoading: false,
            }
        }
        case REWORK_CONTENT_ERR: {
            return {
                ...state,
                contentReworkErr: action.payload,
                contentReworkLoading: true
            }
        }
        case DETERMINANT_REQUEST: {
            return {
                ...state,
                determinant: action.payload,
                determinantLoading: true
            }
        }
        case DETERMINANT_SUCCESS: {
            return {
                ...state,
                determinant: action.payload,
                determinantLoading: false
            }
        }
        case DETERMINANT_ERR: {
            return {
                ...state,
                determinantErr: action.payload,
                determinantLoading: true
            }
        }
        case DETERMINANT_CATOGORIES_REQUEST: {
            return {
                ...state,
                determinantCategories: action.payload,
                determinantCategoriesLoading: true
            }
        }
        case DETERMINANT_CATOGORIES_SUCCESS: {
            return {
                ...state,
                determinantCategories: action.payload,
                determinantCategoriesLoading: false
            }
        }
        case DETERMINANT_CATOGORIES_ERR: {
            return {
                ...state,
                determinantCategoriesErr: action.payload,
                determinantCategoriesLoading: true
            }
        }

        case DETERMINANT_CATOGORIES_PRACTICES_REQUEST: {
            return {
                ...state,
                determinantCategoriesPractices: action.payload,
                determinantCategoriesPracticesLoading: true
            }
        }
        case DETERMINANT_CATOGORIES_PRACTICES_SUCCESS: {
            return {
                ...state,
                determinantCategoriesPractices: action.payload,
                determinantCategoriesPracticesLoading: false
            }
        }
        case DETERMINANT_CATOGORIES_PRACTICES_ERR: {
            return {
                ...state,
                determinantCategoriesPracticesErr: action.payload,
                determinantCategoriesPracticesLoading: true
            }
        }

        case GET_CONTENT_USER_DETAIL_REQUEST: {
            return {
                ...state,
                cuntentDetailUser: action.payload,
                cuntentDetailUserLoading: true
            }
        }
        case GET_CONTENT_USER_DETAIL_SUCCESS: {
            return {
                ...state,
                cuntentDetailUser: action.payload,
                cuntentDetailUserLoading: false
            }
        }
        case GET_CONTENT_USER_DETAIL_ERR: {
            return {
                ...state,
                cuntentDetailUserErr: action.payload,
                cuntentDetailUserLoading: true
            }
        }

        case TEACHER_LIST_REQUEST: {
            return {
                ...state,
                teachersList: action.payload,
                teachersListLoading: true
            }
        }
        case TEACHER_LIST_SUCCESS: {
            return {
                ...state,
                teachersList: action.payload,
                teachersListLoading: false
            }
        }
        case TEACHER_LIST_ERR: {
            return {
                ...state,
                teachersListErr: action.payload,
                teachersListLoading: true
            }
        }
        case CREATE_CONTENT_ADMIN_REQUEST: {
            return {
                ...state,
                createContentByAdmin: action.payload,
                createContentByAdminLoading: true
            }
        }
        case CREATE_CONTENT_ADMIN_SUCCESS: {
            return {
                ...state,
                createContentByAdmin: action.payload,
                createContentByAdminLoading: false
            }
        }
        case CREATE_CONTENT_ADMIN_ERR: {
            return {
                ...state,
                createContentByAdminErr: action.payload,
                createContentByAdminLoading: true
            }
        }
        case UPLOAD_FILES_AWS_REQUEST: {
            return {
                ...state,
                uploadFilesRes: action.payload,
                uploadFilesResLoading: true
            }
        }
        case UPLOAD_FILES_AWS_SUCCESS: {
            return {
                ...state,
                uploadFilesRes: action.payload,
                uploadFilesResLoading: false
            }
        }
        case UPLOAD_FILES_AWS_ERR: {
            return {
                ...state,
                uploadFilesResErr: action.payload,
                uploadFilesResLoading: true
            }
        }
        case GET_PARTICULAR_DETERMINANT_REQUEST: {
            return {
                ...state,
                getParticularDeterminantRes: action.payload,
                getParticularDeterminantResLoading: true
            }
        }
        case GET_PARTICULAR_DETERMINANT_SUCCESS: {
            return {
                ...state,
                getParticularDeterminantRes: action.payload,
                getParticularDeterminantResLoagetParticularding: false
            }
        }
        case GET_PARTICULAR_DETERMINANT_ERR: {
            return {
                ...state,
                getParticularDeterminantResErr: action.payload,
                getParticularDeterminantResLoading: true
            }
        }
        case GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_REQUEST: {
            return {
                ...state,
                getParticularDeterminantDetailcountRes: action.payload,
                getParticularDeterminantDetailcountResLoading: true
            }
        }
        case GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_SUCCESS: {
            return {
                ...state,
                getParticularDeterminantDetailcountRes: action.payload,
                getParticularDeterminantDetailcountResLoading: false
            }
        }
        case GET_PARTICULAR_DETERMINANT_DETAILS_COUNT_ERR: {
            return {
                ...state,
                getParticularDeterminantDetailcountResErr: action.payload,
                getParticularDeterminantDetailcountResLoading: true
            }
        }
        default:
            return state;
    }
}

export default content;