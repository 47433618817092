import React, { useEffect } from "react";
import './Determinants.scss';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Option from "@mui/icons-material/MoreHorizOutlined";
import Avatar from '@mui/material/Avatar';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Woman from "../../assets/images/woman.png";
import Like from "../../assets/images/likes-2.png";
import Comment from "../../assets/images/under-review.png";
import DisLike from "../../assets/images/dislikes.png";
import { useNavigate } from "react-router";
import { connect } from 'react-redux';
import { determinant } from "../../redux/action/content";
import Hoc from "../../component/HOC";

const Determinants = (props) => {
    const navigate = useNavigate();

    const GoDetailPage = (data) => {
        navigate('/determinants_details/'+data?.Determinant);
    }

    useEffect(() => {
        props.determinant();
    }, [])
    
    return (
        <Hoc activeMenu="Determinants" subTitle={"10 sub categories"}>
            <div className="mt-4">
                <TableContainer
                    component={Paper}
                    sx={{
                        backgroundColor: "#0f1c1e",
                        border: "1px solid #273334",
                        color: "#fff",
                        "& th": {
                            color: "#fff",
                        },
                    }}
                >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow className="headerBorder">
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Total Categories</TableCell>
                                <TableCell align="center">Total Sub Categories</TableCell>
                                <TableCell align="center">Total Contents</TableCell>
                                <TableCell align="center">Quick Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ color: "#fff" }}>
                            {
                                props?.determinantData?.jpDeterminantsData?.map((data, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            onClick={() => GoDetailPage(data)}
                                            className="table-row"
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                                "& th": {
                                                    color: "#fff",
                                                    borderBottom: "1px solid #273334",
                                                },
                                                "& td": {
                                                    borderBottom: "1px solid #273334",
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                align="left"
                                                className="text-white"
                                            >
                                                <div
                                                    className="d-flex overEffect align-items-center"
                                                >
                                                    <Avatar className="mx-3" 
                                                        alt="Travis Howard" 
                                                        src={data?.ICON ? require(`../../assets/determine${data?.ICON}`):''} 
                                                    />
                                                    <div>
                                                        <span className="nameTextDeterminats">
                                                            {data?.Determinant}
                                                        </span>
                                                    </div>
                                                </div>
                                            </TableCell>

                                            <TableCell
                                                align="center"
                                                className="text-white qualification"
                                            >
                                                5
                                            </TableCell>
                                            <TableCell align="center" className="expirence">
                                                25
                                            </TableCell>
                                            <TableCell align="right" className="text-white">
                                                <div>
                                                    <span className="totalContentNumber">140</span>
                                                    <span className="user-table-like statushText">
                                                        {" "}
                                                        <img
                                                            src={Like}
                                                            alt="like"
                                                            width={18}
                                                            style={{ marginRight: "7px" }}
                                                        />
                                                        status
                                                    </span>
                                                    <span className="user-table-like statushText">
                                                        {" "}
                                                        <img
                                                            src={Comment}
                                                            alt="like"
                                                            width={18}
                                                            style={{ marginRight: "7px" }}
                                                        />
                                                        status
                                                    </span>
                                                    <span className="user-table-like statushText">
                                                        {" "}
                                                        <img
                                                            src={DisLike}
                                                            alt="like"
                                                            width={18}
                                                            style={{ marginRight: "7px" }}
                                                        />
                                                        Status
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" className="text-white">
                                                <Option />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Hoc>
    )
}

Determinants.propTypes = {};
Determinants.defaultProps = {};

const actionCreators = {
    determinant
};

export default connect(
    ({ content }) => ({
        determinantData: content.determinant,
    }),
    actionCreators
)(Determinants);